import { Factory } from './factory';
import { Constants } from './constants';
import { Color } from './color';

export const DEFAULT_STYLE = { 'fill' : `${Constants.BackgroundColor}` };
const SIDE_NAMES = [
  'entryMaster',
  'entrySlave',
  'exitMaster',
  'exitSlave'
];

export class GateLight extends Factory {
  entryMaster = DEFAULT_STYLE;
  entrySlave = DEFAULT_STYLE;
  exitMaster = DEFAULT_STYLE;
  exitSlave = DEFAULT_STYLE;
  doorMaster = DEFAULT_STYLE;
  doorSlave = DEFAULT_STYLE;

  static fromJson<T>(type: new () => T, json: any, source?: T): T {
    const r = {};
    Object.keys(json).forEach(key => {
        const value = Color.adjust('#' + json[key]);
        const style = {'fill': `${value}`};
        if (SIDE_NAMES.includes(key)) {
          style['stroke'] = '#ccc';
          style['stroke-width'] = '1px';
        }
        r[key] = style;
    });
    return Object.assign(new type(), r);
  }

  static buildStyleString(style: any): string {
      const r = [];
      const f = (attrName: string) => {
        if (attrName in style) {
          r.push(`${attrName}: ${style[attrName]}`);
        }
      };
      f('fill');
      f('stroke');
      f('stroke-width');
      return r.join(' ');
  }

  static getDefaultStyleString(): string {
    return GateLight.buildStyleString(DEFAULT_STYLE);
  }
}
