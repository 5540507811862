import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { GpioContact } from '../../../models/bridge/gpio-contact';

@Component({
  selector: 'app-gate-controls-gpio',
  templateUrl: 'gate-controls-gpio.component.html',
  styleUrls: ['gate-controls-gpio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GateControlsGpioComponent {
  @Input() gpioContacts: GpioContact[];
  @Input() isUiControlsEnabled: boolean;

  onClick(gpioContact: GpioContact, value: boolean) {
    gpioContact.value = value;
  }
}
