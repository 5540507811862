import { Injectable, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from './log/log.service';
import { Locale } from '../models/locale';
import { Subject } from 'rxjs';

const LOCALES = [
  new Locale('en', 'English'),
  new Locale('de', 'Deutsch')
];
const COOKIE_KEY = 'localeId';

export function getLanguageFactory(languageService: LanguageService) {
  return languageService.selectedLocale.id;
}

@Injectable()
export class LanguageService {
  private _selectedLocale: Locale;
  languageChanged = new Subject();

  constructor(
    private log: LogService,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) {
    const languageId = this.cookieService.get(COOKIE_KEY);
    this.setSelectedLanguageById(languageId);
  }

  get locales() {
    return LOCALES;
  }

  get defaultLocale(): Locale {
    return LOCALES[0];
  }
  get selectedLocale(): Locale {
    return this._selectedLocale;
  }

  set selectedLocale(value: Locale) {
    const languages = this.locales.filter(x => x === value);
    if (languages.length === 0) {
      this.log.error(
        `LanguageService. selectedLocale. locale not found: '${value}'`
      );
      return;
    }

    if (this._selectedLocale === value) {
      return;
    }
    this._selectedLocale = value;

    this.cookieService.put(COOKIE_KEY, value.id);
    this.translateService.use(value.id);
    this.languageChanged.next();
  }

  setSelectedLanguageById(value: string) {
    value = value ? value : this.defaultLocale.id;
    let locale = this.getLocaleByLanguageId(value);
    if (locale == null) {
      locale = this.getLocaleByLanguageId(this.defaultLocale.id);
    }
    this.selectedLocale = locale;
  }

  getLocaleByLanguageId(value: string): Locale {
    const locales = this.locales.filter(x => x.id === value);
    if (locales.length > 0) {
      return locales[0];
    }

    this.log.error(
      `LanguageService. getLocaleByLanguageId. locale not found by: '${value}'`
    );
    return null;
  }

  isDefaultLocale(value: Locale): boolean {
    return value === this.defaultLocale;
  }

  loadTranslations(translations: any): boolean {
    if (!translations) {
      return false;
    }
    const languageId = this.cookieService.get(COOKIE_KEY);

    Object.entries(translations).forEach(
      ([lang, values]) => {
        this.translateService.use(lang).subscribe(
          result => {
            this.translateService.setTranslation(lang, values, true);
            this.translateService.use(languageId);
          }
        );
      }
    );

    return true;
  }
}
