import { Injectable, Injector, OnDestroy } from '@angular/core';
import { AppStateService } from '../app-state.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { Role } from 'src/app/models/role/role';
import { IResource } from 'src/app/models/role-resource/resource';
import { Subscription } from 'rxjs';
import { BackendApiService } from './backend-api.service';
import { IBackendApi } from './backend-api.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InformationPopupComponent } from 'src/app/modules/shared/components/information-popup/information-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { FormViewSettings } from 'src/app/modules/shared/models/form-view-settings';

const defaultUrl = '/dashboard';

@Injectable()
export class RolesService {
  role: Role;
  url: string = null;
  private defaultUrl: string = null;
  private pathDict: { [path: string]: string[]; } = null;
  private authService: AuthService;
  private apiStateService: AppStateService;
  private _currentSetupStep: IResource = null;
  private setupSteps: IResource[] = [];
  private finishFirstSetupSubscription: Subscription;
  private backendApi: IBackendApi;

  private defaultModelPageUrl = '/fmcu-configuration/devices';


  constructor(
    private router: Router,
    protected injector: Injector,
    private backendApiService: BackendApiService,
    private modalService: NgbModal,
    private translateService: TranslateService,
  ) {
    this.backendApi = backendApiService.backendApi;
    this.authService = injector.get(AuthService);
    this.authService.authenticationChanged.subscribe((x) => this.onAuthenticationChanged(x));
    this.apiStateService = injector.get(AppStateService);
    this.apiStateService.appState.roleReceived.subscribe(x => this.onRoleReceived(x));
  }

  canAccessPage(url: string): boolean {
    if (this.pathDict && '*' in this.pathDict) {
      return true;
    }

    if (this.role && !this.role.isFirstSetupStepsFinished
      && url === this.defaultModelPageUrl
      && this.currentSetupStep && this.currentSetupStep.modelObjectId) {
      return true;
    }

    return this.pathDict && url in this.pathDict;
  }

  canAccessResource(url: string, resource: string): boolean {
    if (!url || !resource || !this.canAccessPage(url)) {
      return false;
    }

    let path_resources = this.pathDict[url];
    if ('*' in this.pathDict) {
      path_resources = this.pathDict['*'];
    }

    if (path_resources.indexOf('*') >= 0) {
      return true;
    }

    if (this.isSetupStepsActive) {
      path_resources = this.currentSetupStep.resourceIds;
    }

    return path_resources.indexOf(resource) >= 0;
  }

  getDefaultUrl(): string {
    if (this.defaultUrl) {
      return this.defaultUrl;
    }

    const pathDict = this.pathDict;
    if (this.pathDict == null) {
      return defaultUrl;
    }

    if (!this.role.isFirstSetupStepsFinished && this.currentSetupStep && this.currentSetupStep.path) {
      if (this.currentSetupStep.modelObjectId) {
        return this.defaultModelPageUrl;
      }
      return this.currentSetupStep.path;
    }

    const keys = Object.keys(pathDict);
    if (keys.length > 0) {
      return keys[0] !== '*' ? keys[0] : '';
    }
    return defaultUrl;
  }

  private onAuthenticationChanged(isAuthenticated: boolean) {
    if (!isAuthenticated) {
      this.reset();
    }
  }

  private onRoleReceived(role) {
    if (!role) {
      return;
    }

    this.role = role;

    const resources = role.resources;
    this.pathDict = {};

    if (!this.role.isFirstSetupStepsFinished) {
      this.setupSteps = role.firstSetupSteps;
      if (this.setupSteps && this.setupSteps.length > 0) {
        this._currentSetupStep = this.setupSteps[0];
        this.setupSteps.map(resourceStep => {
          this.fillPathDictByResource(resourceStep);
        });
      }
    }

    if (!this.setupSteps || this.setupSteps.length === 0) {
      resources.map(resource => {
        this.fillPathDictByResource(resource);
      });
    }

    this.redirect();
    this.initializeCurrentSetupStep();
  }

  fillPathDictByResource(resource: IResource) {
    const allowedResourceIds = resource.resourceIds ? resource.resourceIds : [];
    if (resource.path) {
      if (resource.path in this.pathDict) {
        this.pathDict[resource.path].push(...allowedResourceIds);
      } else {
        this.pathDict[resource.path] = [...allowedResourceIds];
      }
    }
  }

  get currentSetupStep(): IResource {
    return this._currentSetupStep;
  }

  private redirect() {
    if (!this.authService.isAuthenticated) {
      console.error('Expected authenticated');
    }

    let url = this.url;
    if (['/', '', null, undefined].some(x => x === url)) {
      url = this.getDefaultUrl();
    }

    if (!this.canAccessPage(url)) {
      console.error(`unauthorised. url: ${url}`);
      url = this.getDefaultUrl();
    }

    this.router.navigate([url]);
  }

  private reset() {
    this.role = null;
    this.pathDict = null;
    this.url = null;
    this.defaultUrl = null;
    this._currentSetupStep = null;
    this.setupSteps = null;
    this.finishFirstSetupSubscriptionUnsubscribe();
  }

  get currentSetupStepPath(): string {
    if (!this.currentSetupStep || !this.currentSetupStep.path) {
      return '';
    }

    return this.currentSetupStep.path;
  }

  get currentSetupStepModelObjectId(): string {
    if (!this.currentSetupStep || !this.currentSetupStep.modelObjectId) {
      return '';
    }

    return this.currentSetupStep.modelObjectId;
  }

  get currentSetupStepFormViewSettings(): FormViewSettings {
    if (!this.currentSetupStep || !this.currentSetupStep.formViewSettings) {
      return null;
    }

    return this.currentSetupStep.formViewSettings;
  }


  get currentSetupStepPosition(): number {
    if (!this.setupSteps || this.setupSteps.length === 0) {
      return -1;
    }

    return this.setupSteps.indexOf(this.currentSetupStep);
  }

  get isFirstSetupStep(): boolean {
    return this.setupSteps && this.setupSteps.length > 0 && this.currentSetupStepPosition === 0;
  }

  get isLastSetupStep(): boolean {
    if (!this.setupSteps || !this.setupSteps.length) {
      return true;
    }

    return this.currentSetupStepPosition + 1 === this.setupSteps.length;
  }

  setNextSetupStep() {
    if (this.currentSetupStepPosition < 0 || this.isLastSetupStep) {
      return;
    }

    this.redirectToSetupStep(true);
  }

  setPreviousSetupStep() {
    if (this.currentSetupStepPosition < 0 || this.isFirstSetupStep) {
      return;
    }

    this.redirectToSetupStep(false);
  }

  redirectToSetupStep(isNext: boolean) {
    const oldSetupStep = this.currentSetupStep;

    if (isNext) {
      this._currentSetupStep = this.setupSteps[this.currentSetupStepPosition + 1];
    } else {
      this._currentSetupStep = this.setupSteps[this.currentSetupStepPosition - 1];
    }

    if (this.currentSetupStep.modelObjectId) {
      if (oldSetupStep && oldSetupStep.modelObjectId) {
        return;
      } else {
        this.router.navigate([this.defaultModelPageUrl]);
        return;
      }
    }

    if (this.currentSetupStep.path) {
      this.router.navigate([this.currentSetupStep.path]);
    }
  }

  initializeCurrentSetupStep() {
    if (!this.setupSteps || (this.role && this.role.isFirstSetupStepsFinished)) {
      return;
    }

    if (this.router.url && this.currentSetupStep && this.currentSetupStep.path && this.currentSetupStep.path === this.router.url) {
      return;
    }

    for (const setupStep of this.setupSteps) {
      if (setupStep.path === this.router.url || (setupStep.modelObjectId && this.defaultModelPageUrl === this.router.url)) {
        this._currentSetupStep = setupStep;
        break;
      }
    }
  }

  get isSetupStepsActive(): boolean {
    return this.currentSetupStepPosition >= 0;
  }

  finishSetupSteps(isNeedRestart: boolean) {
    if (!this.role || !this.setupSteps || !this.setupSteps.length || !this.isLastSetupStep) {
      return;
    }

    this.finishFirstSetupSubscriptionUnsubscribe();
    this.finishFirstSetupSubscription = this.backendApiService.backendApi.finishFirstSetupSteps(this.role.objectId, isNeedRestart)
      .subscribe(
        () => {
          this.role.isFirstSetupStepsFinished = true;
          this.authService.logout();
        },
        (e) => {
          this.showErrorPopup(this.fromError(e));
        });
  }

  finishFirstSetupSubscriptionUnsubscribe() {
    if (this.finishFirstSetupSubscription) {
      this.finishFirstSetupSubscription.unsubscribe();
      this.finishFirstSetupSubscription = null;
    }
  }

  showErrorPopup(popupMessage: string) {
    this.modalService.dismissAll();
    const errorModal = this.modalService.open(InformationPopupComponent);
    errorModal.componentInstance.modalHeader = this.t('Error');
    errorModal.componentInstance.modalMessage = popupMessage || 'Error';
    errorModal.componentInstance.hideCancel = true;
  }

  private t(x: string) {
    return this.translateService.instant(x);
  }

  fromError(e) {
    return e ? e.statusText || e.message : null;
  }

  get currentStepName(): string {
    if (!this.currentSetupStep) {
      return '';
    }

    return this.currentSetupStep.instanceName;
  }
}
