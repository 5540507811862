
import { baseHttpUrl } from '../../configuration';

export class DisplayStatus {
  name: string;
  instance_name: string;
  urlPathDisplay: string;

  constructor(name: string, instance_name: string, urlPathDisplay: string) {
    this.name = name;
    this.instance_name = instance_name;
    this.urlPathDisplay = urlPathDisplay;
  }

  get url(): string {
    return `${baseHttpUrl}${this.urlPathDisplay}`;
  }
}

export class DisplaysStatus {
  _map = new Map<string, DisplayStatus>();

  getItem(id: string): DisplayStatus {
    return this._map.get(id);
  }

  get items(): DisplayStatus[] {
    return Array.from(this._map.values());
  }

  get is_displays_exist() {
    return this.items.length > 0;
  }

  updateFromJson(json: any) {
    this._map.clear();
    json.forEach(x => {
      const name = x.name;
      const instance_name = x.instance_name;
      let item = this.getItem(name);
      if (item == null) {
        item = new DisplayStatus(name, instance_name, x.url_path_display);
        this._map.set(name, item);
      }
    });
  }
}
