export class Utils {
  static isNumeric(n): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static isDictionary(obj): boolean {
    return (
      obj !== null &&
      typeof obj === 'object' &&
      !(obj instanceof Array) &&
      !(obj instanceof Date)
    );
  }

  static clone(x: any): any {
    if (x == null) {
      return x;
    }
    return JSON.parse(JSON.stringify(x));
  }

  static isValidIPaddress(ipAddress: string): boolean {
    return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipAddress);
  }
}
