enum OperationState {
  None = 'None',
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure'
}

export class Operation {
  date: Date = new Date();
  lastError = '';
  state: OperationState;
  name: string;

  constructor() {
    this.reset();
  }

  reset() {
    this.state = OperationState.None;
    this.name = '';
  }

  get canReset(): boolean {
    return (new Date().getTime() - this.date.getTime()) > 500;
  }

  start(name: string) {
    this.lastError = '';
    this.state = OperationState.InProgress;
    this.name = name;
  }

  stopSuccess() {
    this.state = OperationState.Success;
    this.date = new Date();
  }

  stopFailure(e: any) {
    this.lastError = e.statusText || e.message;
    this.state = OperationState.Failure;
    this.date = new Date();
  }

  get isNone(): boolean {
    return this.state === OperationState.None;
  }

  get isInProgress(): boolean {
    return this.state === OperationState.InProgress;
  }

  get isSuccess(): boolean {
    return this.state === OperationState.Success;
  }

  get isFailure(): boolean {
    return this.state === OperationState.Failure;
  }
}
