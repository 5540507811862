import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BackendApiService } from './backend-api/backend-api.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { StringifyOptions } from 'querystring';
import { AppStateService } from './app-state.service';
import { BackendEventsService } from './backend-events/backend-events.service';
import { Cookie } from '../models/cookie';

export const TOKEN = 'jwt';
export const LOGIN_COMMAND = '/login';
export const TOKEN_UDPATE_BEFORE_EXPIRATION_SECONDS = 30;

@Injectable()
export class AuthService {
  private _isAuthenticated = false;
    private _appStateService: AppStateService;
  authenticationChanged = new Subject<boolean>();
  isLoginInProgressChanged = new Subject<boolean>();
  loginError: string;
  private _isLoginInProgress = false;
  isTokenUpdateInProgress = false;
  username: string;

  constructor(
    private router: Router,
    private backendApiService: BackendApiService,
    private backendEventsService: BackendEventsService,
    private jwtHelperService: JwtHelperService
  ) {
    this.backendEventsService.backendEvents.fmcuDateChanged.subscribe(x =>
      this.updateState()
    );

    this.updateState();
  }

  get appStateService(): AppStateService {
    return this._appStateService;
  }

  set appStateService(appStateService: AppStateService) {
    this._appStateService = appStateService;
  }

  login(username: string, password: string) {
    this.loginError = null;
    this.isLoginInProgress = true;
    this.backendApiService.backendApi.login(username, password).subscribe(
      x => this.updateToken(x.token),
      e => {
        this.isLoginInProgress = false;
        this.loginError = e.statusText || e.message;
      }
    );
  }

  logout(removeToken = true) {
    this.isAuthenticated = false;
    if (removeToken) {
      Cookie.remove(TOKEN);
    }
    this.updateUsername(null);
    this.router.navigate([LOGIN_COMMAND]);
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  set isAuthenticated(value: boolean) {
    if (value === this.isAuthenticated) {
      return;
    }

    this._isAuthenticated = value;
    this.authenticationChanged.next(value);
  }

  private updateState() {
    const token = Cookie.get(TOKEN);
    const isAuthenticated = !this.isTokenExpired(token);
    this.isAuthenticated = isAuthenticated;
    if (isAuthenticated) {
      this.prolongateToken(token);
      this.updateUsername(token);
    } else {
      this.username = '';
    }
  }

  private prolongateToken(token: any) {
    if (
      token == null ||
      this.isTokenUpdateInProgress ||
      !this.isTokenExpired(token, TOKEN_UDPATE_BEFORE_EXPIRATION_SECONDS)
    ) {
      return;
    }

    this.isTokenUpdateInProgress = true;
    this.backendApiService.backendApi.prolongateToken(token).subscribe(x => {
      this.updateToken(x.token);
      this.isTokenUpdateInProgress = false;
    }, e => (this.isTokenUpdateInProgress = false));
  }

  private isTokenExpired(token: any, shiftInSeconds = 0): boolean {
    const backendEventsService = this.backendEventsService;
    if (token == null || backendEventsService == null) {
      return true;
    }

    const fmcuDate = backendEventsService.backendEvents.fmcuDate;
    if (fmcuDate == null) {
      return true;
    }

    const tokenDate = this.jwtHelperService.getTokenExpirationDate(token);
    const tokenDateStamp = tokenDate.getTime();
    const fmcuDateStamp = fmcuDate.getTime();
    const diff = tokenDateStamp - fmcuDateStamp - shiftInSeconds * 1000;
    return diff < 0;
  }

  private updateToken(token: string) {
    if (token != null) {
      this.isLoginInProgress = false;
      Cookie.put(TOKEN, token, 30);
      this.updateState();
    }
  }

  get isLoginInProgress(): boolean {
    return this._isLoginInProgress;
  }

  set isLoginInProgress(value: boolean) {
    if (value === this.isLoginInProgress) {
      return;
    }

    this._isLoginInProgress = value;
    this.isLoginInProgressChanged.next(value);
  }

  private updateUsername(token: any) {
    const payload = token == null ? null : this.jwtHelperService.decodeToken(token);
    this.username = payload == null ? null : payload.username;
  }
}
