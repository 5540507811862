import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { RandomWinnerHistoryItem } from 'src/app/models/random-winner/random-winner-history-item';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AppState } from 'src/app/models/app-state';
import { Subscribable, Subscription } from 'rxjs';
import { ExportToCsv } from 'export-to-csv';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedDatePipe } from 'fmcu-core-ng';

@Component({
  selector: 'app-random-winner-history',
  templateUrl: 'random-winner-history.component.html',
  styleUrls: ['random-winner-history.component.scss'],
  providers: [LocalizedDatePipe]
})
export class RandomWinnerHistoryComponent implements OnInit, OnDestroy {
  private itemsDict: { [id: number]: RandomWinnerHistoryItem; } = {};
  private backendApi: IBackendApi;
  private appState: AppState;
  private randomWinnerHistoryChangedSubscription: Subscription;

  height = 300;
  rowHeight = 40;
  headerHeight = 40;
  footerHeight = 40;
  filteredCount = 0;

  constructor(
    private backendApiService: BackendApiService,
    private translateService: TranslateService,
    private localizedDatePipe: LocalizedDatePipe,
    private appStateService: AppStateService,
  ) {
    this.backendApi = backendApiService.backendApi;
    this.appState = appStateService.appState;
  }

  ngOnInit(): void {
    this.randomWinnerHistoryChangedSubscription = this.appState.randomWinnerHistoryChanged.subscribe(x => this.update(x));
    this.reload();
  }

  ngOnDestroy(): void {
    if (this.randomWinnerHistoryChangedSubscription) {
      this.randomWinnerHistoryChangedSubscription.unsubscribe();
    }
  }

  reload() {
    this.backendApi.getRandomWinnerHistory().subscribe(
      json => {
        const dict = {};
        if (json && json.history) {
          json.history.forEach(x => dict[x.id] = RandomWinnerHistoryItem.fromJson(x));
        }
        this.itemsDict = dict;
      }
    );
  }

  private update(json: any) {
    const dict = this.itemsDict;
    const f = x => RandomWinnerHistoryItem.fromJson(x);
    json.added.concat(json.updated).forEach(x => dict[x.id] = f(x));
    json.deleted.forEach(x => delete dict[x.id]);
  }

  get items(): RandomWinnerHistoryItem[] {
    return Object.values(this.itemsDict).sort((a, b) => b.id - a.id);
  }

  get headers(): string[] {
    return ['Date', 'Direction', 'Count', 'Barcode', 'Reset at', 'Reset by'];
  }

  saveToCsvFile() {
    const fileName = `random-winner-${new Date().getTime()}`;
    const headers = this.headers.map(x => this.translateService.instant(x));
    const options = {
      filename: fileName,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: 'locale',
      showLabels: true,
      showTitle: false,
      useBom: true,
      headers: headers
    };

    const csvExporter = new ExportToCsv(options);
    const data = this.items.map(x => this.buildExportedRow(x));
    csvExporter.generateCsv(data);
  }

  private buildExportedRow(x: RandomWinnerHistoryItem): any {
    const r = {};
    const t = this.translateService;

    const l = (d: Date) => `${this.localizedDatePipe.transform(d, 'mediumDate')} ${this.localizedDatePipe.transform(d, 'mediumTime')}`;

    r['date'] = l(x.dateCreated);
    r['direction'] = t.instant(x.direction);
    r['count'] = x.count;
    r['barcode'] = x.barcode;
    r['resetAt'] = l(x.dateReset);
    r['resetBy'] = t.instant(x.resetSource);
    return r;
  }

  get isEmpty(): boolean {
    return this.items == null || this.items.length === 0;
  }
}
