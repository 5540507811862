import { Component, Input, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { FileUtility } from 'fmcu-core-ng';

@Component({
  selector: 'app-file-selection',
  templateUrl: 'file-selection.component.html',
  styleUrls: ['file-selection.component.scss']
})
export class FileSelectionComponent implements AfterContentChecked {
  @Input() files: File[];
  @Input() downloadHandler;
  @Input() uploadHandler;
  @Input() cancelHandler;
  @Input() canDownload;
  @Input() canUpload;
  @Input() canCancel;
  @Input() canIo = true;
  @Input() fileMask: string;
  @Input() multiple = false;

  constructor(private cdref: ChangeDetectorRef) { }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  get fileName(): string {
    return this.files == null ? null : this.files.map(x => x.name).join(',');
  }

  selectFileClick() {
    FileUtility.selectFiles(this.fileMask || '*.*', (files: FileList) => {
      this.files = Array.from(files);
    }, this.multiple);
  }

  uploadClick() {
    if (this.uploadHandler) {
      this.uploadHandler(this.files);
      return;
    }
    alert('uploadHandler not set');
  }

  downloadClick() {
    if (this.downloadHandler) {
      this.downloadHandler();
      return;
    }

    alert('downloadHandler not set');
  }

  get canUploadFile(): boolean {
    return this.canUpload && this.fileName != null;
  }

  cancelClick() {
    this.cancelHandler();
  }
}
