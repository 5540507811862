import { Component, ViewEncapsulation } from '@angular/core';
import { StatisticsService } from 'src/app/services/statistics.service';

@Component({
  selector: 'app-statistics-page',
  templateUrl: 'statistics-page.component.html',
  styleUrls: ['statistics-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatisticsPageComponent {

  constructor(
    public statisticsService: StatisticsService,
  ) { }
}
