export enum ThreeState {
  On = 'ThreeState.On',
  Off = 'ThreeState.Off',
  Undefined = 'ThreeState.Undefined'
}

export class ThreeStateUtil {
  static toBoolean(st: ThreeState): boolean {
    return st === ThreeState.On;
  }

  static fromString(s: string): ThreeState {
    if (s in ThreeState) {
      return ThreeState[s];
    }

    try {
      return ThreeState[s.split('.')[1]];
    } catch {}

    return null;
  }

}

