import { Component, Input } from '@angular/core';
import { LastEvent } from 'src/app/models/last-event';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-gate-info-events',
  templateUrl: './gate-info-events.component.html',
  styleUrls: ['./gate-info-events.component.css']
})
export class GateInfoEventsComponent {

  constructor(
    private appStateService: AppStateService
  ) {}

  @Input()
  get events(): LastEvent[] {
    return this.appStateService.appState.lastEvents.events;
  }
}
