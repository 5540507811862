import { Component } from '@angular/core';
import { AppStateService } from '../../../services/app-state.service';
import { Alarm } from 'src/app/models/alarm';

@Component({
  selector: 'app-gate-info-alarm',
  templateUrl: 'gate-info-alarm.component.html',
  styleUrls: ['gate-info-alarm.component.scss']
})
export class GateInfoAlarmsComponent {
  constructor(
    private appStateService: AppStateService,
  ) { }

  get alarms(): Alarm[] {
    return this.appStateService.appState.alarms;
  }
}
