export class LazyExecutor {
  lastTime = 0;
  minInterval: number;
  postponed = false;
  f: () => {};

  constructor(minInterval = 200) {
    this.minInterval = minInterval;
  }

  execute(f: any) {
    const now = Date.now();
    const diff = now - this.lastTime;
    const postInterval = this.minInterval - diff;
    if (postInterval > 0) {
      this.f = f;
      if (!this.postponed) {
        this.postponed = true;
        setTimeout(() => {
          if (this.postponed) {
            this.f();
            this.postponed = false;
          }

        }, postInterval);
      }
    } else {
      f();
      this.lastTime = now;
      this.postponed = false;
    }
  }
}
