import { TranslateService } from '@ngx-translate/core';

export const REGEX = /<(.+?)>/g;

export class TranslatorRegex {
  private translateService: TranslateService;
  constructor(translateService: TranslateService) {
    this.translateService = translateService;
  }

  translate(x: string): string {
    if (x == null || x === '') {
      return x;
    }

    const t = this.translateService;

    if (!new RegExp(REGEX).test(x)) {
      return t.instant(x);
    }

    const result = x.replace(REGEX, function(a, b) {
      return t.instant(b);
    });

    return result;
  }
}
