import {
  Component,
  DoCheck,
  ElementRef,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-navigation-button',
  templateUrl: 'navigation-button.component.html',
  styleUrls: ['navigation-button.component.scss']
})
export class NavigationButtonComponent implements DoCheck {
  _visible = false;
  @Output() clicked = new EventEmitter();
  @Output() visibilityChanged = new EventEmitter<boolean>();

  constructor(private element: ElementRef) {}

  onClick() {
    this.clicked.next();
  }

  ngDoCheck() {
    const style = window.getComputedStyle(
      this.element.nativeElement.children[0]
    );
    this.visible = style.display !== 'none';
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    if (value === this.visible) {
      return;
    }

    this._visible = value;
    this.visibilityChanged.next(value);
  }
}
