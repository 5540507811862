import { Component } from '@angular/core';

@Component({
      selector: 'app-cards-page',
    templateUrl: 'cards-page.component.html',
    styleUrls: ['cards-page.component.scss']
})
export class CardsPageComponent {

}
