export class Alarm {
  id: string;
  name: string;
  lastActivated: Date;

  static createFromJson(json: any) {
    const alarm = new Alarm();
    alarm.id = json.object_id;
    alarm.name = json.name;
    alarm.lastActivated = new Date(json.last_activated);
    return alarm;
  }
}
