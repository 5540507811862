import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { RandomWinnerTracker } from './random-winner-tracker';
import { JsonConvertorUtility } from 'fmcu-core-ng';

@JsonConverter
export class RandomWinnerTrackerConverter implements JsonCustomConvert<RandomWinnerTracker> {
    serialize(x: RandomWinnerTracker): any {
        throw new Error('Not implemented');
    }

    deserialize(x: any): any {
      return JsonConvertorUtility.deserialize(x, RandomWinnerTracker);
    }
}
