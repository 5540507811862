export class FormViewSettings {
  hideCategories = false;
  keysToShow: string[] = null;

  constructor( hideCategories = false,
    keysToShow: string[] = null) {
      this.hideCategories = hideCategories;
      this.keysToShow = keysToShow;
    }
}
