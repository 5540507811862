import { Subject } from 'rxjs/Subject';

export interface IBackendStatement {
  backendStatementCreated: Subject<string>;
}

export class Bridge<T> implements IBackendStatement {
  protected _value: T;
  backendStatementCreated = new Subject<string>();


  constructor(value: T) {
    this._value = value;
  }

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
    const exec = this.exec;
    if (exec) {
      this.backendStatementCreated.next(exec);
    }
  }

  get exec(): string {
    return '';
  }

  updateValueFromJson(value: T) {
    this._value = value;
  }
}
