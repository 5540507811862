import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StatisticsCategory, StatisticsItem } from '../../../models/statistics';
import { CssStyleProvider } from '../models/css-style-provider';
import { Utils } from '../../../modules/shared/models/utils';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-gate-info-statistics',
  templateUrl: 'gate-info-statistics.component.html',
  styleUrls: ['gate-info-statistics.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GateInfoStatisticsComponent extends CssStyleProvider {
  @Input() statisticsCategories: StatisticsCategory[];


  constructor(
    private translateService: TranslateService,
    private notificationService: NotifierService,
  ) {
    super();
  }

  getValue(value: string): string {

    if (!value) {
      return '';
    }

    if (Utils.isNumeric(value)) {
      return value;
    }

    return this.translateService.instant(value);
  }

  private copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onValueCellClick(event: any) {
    this.copyText(event.target.innerHTML);
    this.notify('Text copied to clipboard');
  }

  private notify(message: string, type: string = 'info') {
    const body = this.translateService.instant(message);

    this.notificationService.show({
      message: body,
      type: type,
      id: body
    });

    setTimeout(() => {
      this.notificationService.hide(body);
    }, 1000);
  }
}
