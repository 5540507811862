import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
    selector: 'app-formly-field-switch',
    templateUrl: 'formly-field-switch.component.html',
    styleUrls: ['formly-field-switch.component.scss']
})
export class FormlyFieldSwitchComponent extends FieldType {} {

}
