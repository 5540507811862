import { Highlighter } from 'fmcu-core-ng';

class MachineBase extends Highlighter {
  _mapMachines = new Map<string, Machine>();

  get machines(): Machine[] {
    return Array.from(this._mapMachines.values());
  }

  getMachine(id: string): Machine {
    const machine = this._mapMachines.get(id);
    return machine;
  }

  updateMachinesFromJson(json: any) {
    if (!json) {
      return;
    }

    json.forEach(x => {
      const id = x.id;
      let machine = this._mapMachines.get(id);
      if (!machine) {
        machine = new Machine(id);
        this._mapMachines.set(id, machine);
      }
      machine.updateFromJson(x);
    });
  }
}

export class Machine extends MachineBase {
  id: string;
  name: string;
  changeCount: number;
  dateTime: Date;
  state: string;
  _children = new Map<string, Machine>();

  constructor(id: string) {
    super();
    this.id = id;
  }

  updateFromJson(json: any) {
    this.name = json.name;
    this.changeCount = json.change_count;
    this.dateTime = new Date(json.dateTime);
    this.state = json.state;
    this.updateMachinesFromJson(json.children);
    this.highlight();
  }
}

export class StatisticsMachine extends MachineBase {
  updateMachineFromJson(json: any) {
    const x = this.getMachine(json.id);
    if (x) {
      x.updateFromJson(json);
    }
  }
}
