import { Component, Input } from '@angular/core';
import { Machine } from '../../../models/statistics-machine';
import { CssStyleProvider } from '../models/css-style-provider';

@Component({
    selector: 'app-gate-info-machines',
    templateUrl: 'gate-info-machines.component.html',
    styleUrls: ['gate-info-machines.component.scss']
})
export class GateInfoMachinesComponent extends CssStyleProvider {
  @Input() machines: Machine[];
}
