import { LoginPageComponent } from './pages/login-page/login-page.component';
import { AuthGuardService } from './services/auth-guard.service';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { StatusPageComponent } from './pages/status-page/status-page.component';
import { FilesPageComponent } from './pages/files-page/files-page.component';
import { CardsPageComponent } from './pages/cards-page/cards-page.component';
import { StatisticsPageComponent } from './pages/statistics-page/statistics-page.component';
import { CameraPageComponent } from './pages/camera-page/camera-page.component';
import { RandomWinnerPageComponent } from './pages/random-winner-page/random-winner-page.component';
import { UnauthorisedPageComponent } from './pages/unauthorised-page/unauthorised-page.component';
import { LoadingPageComponent } from './pages/loading-page/loading-page.component';
import { FmcuConfigurationPageComponent } from './pages/fmcu-configuration-page/fmcu-configuration-page.component';
import { MainPageComponent as HomePageComponent } from './pages/home-page/home-page.component';
import { FmcuSetupPageComponent } from './pages/fmcu-setup-page/fmcu-setup-page.component';
import { ConfigurationDeviceComponent } from './modules/configuration/configuration-device/configuration-device.component';
import { WeacFirmwareComponent } from './modules/weac/components/weac-firmware/weac-firmware.component';
import { UpdateDiamexComponent } from './modules/configuration/components/update-diamex/update-diamex.component';

export const routes: Routes = [
  { path: 'dashboard', component: DashboardPageComponent, canActivate: [AuthGuardService] },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full', canActivate: [AuthGuardService] },
  { path: 'login', component: LoginPageComponent },
  { path: 'logout', redirectTo: '/login', pathMatch: 'full' },
  { path: 'status', component: StatusPageComponent, canActivate: [AuthGuardService] },
  { path: 'files', component: FilesPageComponent, canActivate: [AuthGuardService] },
  { path: 'cards', component: CardsPageComponent, canActivate: [AuthGuardService] },
  {
    path: '',
    loadChildren: './modules/configuration/configuration.module#ConfigurationModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'system',
    loadChildren: './modules/system/system.module#SystemModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'weac',
    loadChildren: './modules/weac/weac.module#WeacModule',
    canActivate: [AuthGuardService],
  },
  {
    path: 'statistics',
    component: StatisticsPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'camera',
    component: CameraPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'random-winner',
    component: RandomWinnerPageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'unauthorised',
    component: UnauthorisedPageComponent,
  },
  {
    path: 'loading',
    component: LoadingPageComponent,
  },
  { path: 'fmcu-configuration', component: FmcuConfigurationPageComponent, canActivate: [AuthGuardService] },
  { path: 'home', component: HomePageComponent, canActivate: [AuthGuardService] },
  {
    path: 'fmcu-configuration',
    component: FmcuSetupPageComponent,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            component: FmcuConfigurationPageComponent,
            canActivate: [AuthGuardService],
          },
          {
            path: 'devices',
            component: ConfigurationDeviceComponent,
            canActivate: [AuthGuardService],
          },
        ]
      }
    ],
  },
];

export const routing = RouterModule.forRoot(routes, {
  // enableTracing: true, // <-- debugging purposes only
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
});
