import { Component, OnInit, OnDestroy } from '@angular/core';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InformationPopupComponent } from 'src/app/modules/shared/components/information-popup/information-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirstSetupStepsStateService } from 'src/app/services/first-setup-steps-state.service';

@Component({
  selector: 'app-fmcu-configuration-page',
  templateUrl: './fmcu-configuration-page.component.html',
  styleUrls: ['./fmcu-configuration-page.component.css']
})
export class FmcuConfigurationPageComponent implements OnInit, OnDestroy {

  private backendApi: IBackendApi;
  error: string = null;
  applyDisabled = false;

  subscription: Subscription;

  constructor(
    private backendApiService: BackendApiService,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private authService: AuthService,
    private firstSetupStepsStateService: FirstSetupStepsStateService,
  ) {
    this.backendApi = backendApiService.backendApi;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptionUnsubscribe();
  }

  applyClick(installerSettingsFileName: string, selectedTemlateConfigurations: []) {
    if (!installerSettingsFileName) {
      return;
    }
    this.applyDisabled = true;
    const reboot = this.firstSetupStepsStateService.isNetworkChanged;
    this.subscriptionUnsubscribe();
    this.subscription = this.backendApiService.backendApi.installerSetConfiguration(installerSettingsFileName, selectedTemlateConfigurations, false, reboot).subscribe(
      () => {
        this.applyDisabled = false;
        this.logout(); },
      (e) => {
        this.applyDisabled = false;
        this.showErrorPopup(this.fromError(e));
      });
  }

  fromError(e) {
    return e ? e.statusText || e.message : null;
  }

  resetError() {
    this.error = null;
  }

  logout() {
    return this.authService.logout();
  }

  subscriptionUnsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  showErrorPopup(popupMessage: string) {
    this.modalService.dismissAll();
    const errorModal = this.modalService.open(InformationPopupComponent);
    errorModal.componentInstance.modalHeader = this.t('Error');
    errorModal.componentInstance.modalMessage = popupMessage;
    errorModal.componentInstance.hideCancel = true;
  }

  private t(x: string) {
    return this.translateService.instant(x);
  }

}
