import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { BarcodeReader } from '../../../models/bridge/barcode-reader';
import { GpioContact } from '../../../models/bridge/gpio-contact';
import { ComboBoxItem } from 'fmcu-core-ng';

@Component({
  selector: 'app-gate-controls-side',
  templateUrl: 'gate-controls-side.component.html',
  styleUrls: ['gate-controls-side.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GateControlsSideComponent {
  barcode = '';
  @Input() gpioContacts: GpioContact[];
  @Input() barcodeReader: BarcodeReader;
  @Input() testBarcodes: ComboBoxItem[];
  @Input() isUiControlsEnabled: boolean;

  get cookieKey(): string {
    return `barcodes-${this.barcodeReader.name}-${this.barcodeReader.readerIndex}`.toLowerCase();
  }
}
