import { Constants } from './constants';

export class Color {
  static hexToRgb(hex): number[] {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

  static rgbToHex(rgb: number[]): string {
    // tslint:disable-next-line:no-bitwise
    return '#' + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
  }

  static adjust(hexColor: string, zeroColor = Constants.BackgroundColor): string {
    switch (hexColor) {
      case '#00ff00':
        return '#5cb85c';
      case '#0000ff':
        return '#337ab7';
      case '#000000':
        return zeroColor;
      default:
        return hexColor;
    }
  }
}
