import { Node } from './node';

export class Leg {
    name: string;
    node: Node;
    radius: number;
    isLeft: boolean;
    domId: string;
    getY: (leg: Leg) => number;

    constructor(name: string, node: Node, getY: (leg: Leg) => number) {
        if (Leg.getNames().find(x => x === name) == null) {
            throw new Error('Bad name');
        }
        this.name = name;
        this.node = node;
        this.radius = this.node.radius * 0.6;
        this.isLeft = this.name === Leg.getNames()[0];
        this.domId = Leg.buildDomId(this.node.id, this.name);
        this.getY = getY;
    }

    static buildDomId(id: number, name: string): string {
        return `leg-${name}-${id}`;
    }

    static getNames(): string[] {
        return ['left', 'right'];
    }

    get x(): number {
        const direction = this.isLeft ? -1 : 1;
        return this.node.x + direction * (this.node.radius + 1.5 * this.radius);
    }

    get y(): number {
        return this.getY(this);
    }
}
