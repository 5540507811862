import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { AuthService } from '../../services/auth.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { RolesService } from 'src/app/services/backend-api/roles.service';

interface Link {
  href: string;
  text: string;
  enabled: () => boolean;
}

@Component({
  selector: 'app-page-header',
  templateUrl: 'page-header.component.html',
  styleUrls: ['page-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  navigationVisible = false;
  links: Link[] = [];

  constructor(
    private authService: AuthService,
    private languageService: LanguageService,
    public appStateService: AppStateService,
    private rolesService: RolesService,
  ) {
    this.links = [
      {
        href: '/home',
        text: 'Home',
        enabled: null,
      },
      {
        href: '/dashboard',
        text: 'Dashboard',
        enabled: null,
      },
      {
        href: '/status',
        text: 'Status',
        enabled: null,
      },
      {
        href: '/configuration',
        text: 'Configuration',
        enabled: null,
      },
      {
        href: '/system',
        text: 'System',
        enabled: null,
      },
      {
        href: '/weac',
        text: 'WEAC',
        enabled: null,
      },
      {
        href: '/files',
        text: 'Files',
        enabled: null,
      },
      {
        href: '/cards',
        text: 'Cards',
        enabled: this.isReadersExist.bind(this),
      },
      {
        href: '/statistics',
        text: 'Statistics',
        enabled: null,
      },
      {
        href: '/random-winner',
        text: 'Random Winner',
        enabled: this.isRandomWinnerExist.bind(this),
      },
      {
        href: '/camera',
        text: 'Camera',
        enabled: this.isCameraActive.bind(this),
      },
    ];
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  canShowLink(link: Link): boolean {
    if (!this.isAuthenticated) {
      return false;
    }

    if (this.rolesService && this.rolesService.isSetupStepsActive) {
      return false;
    }

    const enabled = link.enabled;
    if (enabled && !enabled()) {
      return false;
    }

    return this.rolesService.canAccessPage(link.href);
  }

  onLanguageIdChanged(languageId: string) {
    this.languageService.setSelectedLanguageById(languageId);
  }

  get languageId(): string {
    return this.languageService.selectedLocale.id;
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  logout() {
    return this.authService.logout();
  }

  toggleNavigation() {
    this.navigationVisible = !this.navigationVisible;
  }

  onToggleButtonVisibilityChanged(value: boolean) {
    if (!value) {
      this.navigationVisible = false;
    }
  }

  get navClass(): string {
    return 'collapse navbar-collapse' + (this.navigationVisible ? ' show' : '');
  }

  get username(): string {
    return this.authService.username;
  }

  private isCameraActive(): boolean {
    return this.appStateService.appState.activeDispatcher && this.appStateService.appState.activeDispatcher.lastUploadPhotoUrl != null;
  }

  private isRandomWinnerExist(): boolean {
    return this.appStateService.appState.randomWinnerExist;
  }

  private isReadersExist(): boolean {
    return this.appStateService.appState.readersExist;
  }
}
