import { Subject } from 'rxjs';
import { GateSide } from './gate-side';
import { Color } from './color';
import { Constants } from './constants';

const MATRIX_SIZE = 8;

export class LedPlayer {
  private _isInitialized: boolean;
  subjectInitialized = new Subject();

  pixels: any;
  gateSide: GateSide;

  name: string = undefined;

  failure: string = undefined;

  scriptName: string = undefined;

  scriptVersion: string = undefined;

  get isInitialized(): boolean {
    return this._isInitialized;
  }

  set isInitialized(x: boolean) {
    if (x === this._isInitialized) {
      return;
    }

    this._isInitialized = x;
    if (x) {
      this.subjectInitialized.next();
    }
  }

  static createFromJson(json: any) {
    const ledPlayer = new LedPlayer();
    ledPlayer.name = json.name;
    ledPlayer.failure = json.failure;
    ledPlayer.scriptName = json.script_name;
    ledPlayer.scriptVersion = json.script_version;
    ledPlayer.isInitialized = true;
    ledPlayer.fillPixels(json.pixels, json.gate_side);
    return ledPlayer;
  }

  fillPixels(pixels: any = null, gateSide = GateSide.NotSet) {
    this.gateSide = gateSide;
    if (pixels == null) {
      this.pixels = this.createDefaultPixels();
      return;
    } else if (pixels.length !== this.width * this.height) {
      console.log(`Bad pixels length: ${pixels.length}`);
      this.pixels = this.createDefaultPixels('#ffff00');
      return;
    } else {
      pixels = pixels.map(x => Color.rgbToHex(x));
      this.pixels = pixels.map(x => Color.adjust(x));
    }
  }

  createDefaultPixels(color = Constants.BorderColor): string[] {
    const pixels = new Array(this.width * this.height);
    for (let i = 0; i < pixels.length; i++) {
      pixels[i] = color;
    }
    return pixels;
  }

  get width(): number {
    return MATRIX_SIZE;
  }

  get height(): number {
    return MATRIX_SIZE;
  }
}
