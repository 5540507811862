import { JsonProperty } from 'json2typescript';
import { JsonConvertorUtility } from 'fmcu-core-ng';

export interface GateSignalJson {
  object_id: string;
  name: string;
  value: boolean;
  enabled: boolean;
  gate_side: string;
}

export class GateSignal {
  @JsonProperty('object_id', String)
  objectId: string = undefined;
  @JsonProperty('name', String)
  name: string = undefined;
  @JsonProperty('count', Number)
  count: number = undefined;
  @JsonProperty('enabled', Boolean)
  enabled: boolean = undefined;
  @JsonProperty('gate_side', String)
  gateSide: string = undefined;

  uiValue = false;

  static fromJson(json: GateSignalJson): GateSignal {
    return JsonConvertorUtility.deserialize(json, GateSignal);
  }

  get domId(): string {
    return `gate-signal-${this.objectId}`;
  }

  updateFromJson(json: GateSignalJson) {
    const newItem = GateSignal.fromJson(json);
    if (newItem.objectId !== this.objectId) {
      console.error('updateFromJson');
      return;
    }
    this.enabled = newItem.enabled;
    this.name = newItem.name;
    this.count = newItem.count;
  }
}
