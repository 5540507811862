import { Component, Input } from '@angular/core';
import { Operation } from '../../models/operation';

@Component({
      selector: 'app-operation',
    templateUrl: 'operation.component.html',
    styleUrls: ['operation.component.scss']
})
export class OperationComponent {
  @Input() operation: Operation;
}
