import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CompositStatusItem } from '../../../models/status/composit-status';
import { CssStyleProvider } from '../models/css-style-provider';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorRegex } from '../../../modules/shared/models/translator-regex';

@Component({
  selector: 'app-gate-info-status',
  templateUrl: 'gate-info-status.component.html',
  styleUrls: ['gate-info-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GateInfoStatusComponent extends CssStyleProvider {
  @Input() statusItems: CompositStatusItem[];
  private translatorRegex: TranslatorRegex;

  constructor(private translateService: TranslateService) {
    super();
    this.translatorRegex = new TranslatorRegex(translateService);
  }

  translate(x: string): string {
    return this.translatorRegex.translate(x);
  }
}
