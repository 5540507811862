import {
  Component,
  ViewEncapsulation,
  OnInit,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
  Input
} from '@angular/core';
import * as $ from 'jquery';
import 'country-select-js';

const COUNTRY_DATA = [
  {
    iso2: 'us',
    name: 'English',
    languageId: 'en'
  },
  {
    iso2: 'de',
    name: 'Deutsch',
    languageId: 'de'
  }
];

const DEFAULT_COUNTRY_DATA = COUNTRY_DATA[0];

@Component({
  selector: 'app-language-switch',
  templateUrl: 'language-switch.component.html',
  styleUrls: ['language-switch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {
  private _languageId = DEFAULT_COUNTRY_DATA.languageId;
  @Input() initialLanguageId: string;
  @Output() languageIdChanged = new EventEmitter<string>();

  constructor(private elementRef: ElementRef) {}

  get languageId(): string {
    return this._languageId;
  }

  set languageId(x: string) {
    if (this._languageId === x) {
      return;
    }
    this._languageId = x;
    this.languageIdChanged.emit(x);
  }

  ngOnInit(): void {
    if (
      this.initialLanguageId &&
      COUNTRY_DATA.find(x => x.languageId === this.initialLanguageId)
    ) {
      this._languageId = this.initialLanguageId;
    }

    ($.fn.countrySelect as any).setCountryData(COUNTRY_DATA);

    $('#country').countrySelect({
      onlyCountries: COUNTRY_DATA.map(x => x.iso2),
      preferredCountries: [],
      responsiveDropdown: true,
      defaultCountry: COUNTRY_DATA.find(x => x.languageId === this.languageId).iso2
    });

    $(this.elementRef.nativeElement)
      .find('.country')
      .bind('click', () => {
        setTimeout(() => {
          const data = $('#country').countrySelect(
            'getSelectedCountryData'
          ) as any;
          this.languageId = data.languageId;
        }, 0);
      });
  }

  ngOnDestroy(): void {
    $(this.elementRef.nativeElement)
      .find('.country')
      .unbind('click');
  }
}
