import {
  Component,
  OnInit,
  OnDestroy,
  ViewChildren,
  QueryList,
  Input
} from '@angular/core';
import { AppStateService } from '../../../services/app-state.service';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { CardComponent, StaffCard } from 'fmcu-core-ng';
import { Subscription } from 'rxjs/Subscription';
import { LocalBarcodesManager } from '../../../models/local-barcodes-manager';
import { TranslateService } from '@ngx-translate/core';
import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { FileUtility } from 'fmcu-core-ng';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-cards',
  templateUrl: 'cards.component.html',
  styleUrls: ['cards.component.scss']
})
export class CardsComponent implements OnInit, OnDestroy {
  @Input() isLocal = false;
  staffCards: StaffCard[] = [];
  staffCardsBackup: StaffCard[] = [];
  subscriptionBarcodeInfosUpdated: Subscription;
  subscriptionTeachingModeChange: Subscription;
  subscriptionTeachingBarcodeChange: Subscription;
  @ViewChildren(CardComponent)
  barcodeComponents: QueryList<CardComponent>;
  isBarcodeAlreadyInUseBound: Function;
  dirty = false;
  invalid = false;
  private localBarcodesManager: LocalBarcodesManager;
  private _saveDisabled = true;
  private _changed = false;
  private _teachingMode = false;
  private _modalShown = false;

  constructor(
    private appStateService: AppStateService,
    private translateService: TranslateService,
    private backendApiService: BackendApiService
  ) {}

  ngOnInit(): void {
    this.isBarcodeAlreadyInUseBound = this.isBarcodeAlreadyInUse.bind(this);
    this.localBarcodesManager = this.appStateService.appState.localBarcodesManager;

    const reload = () =>
      this.reload(this.localBarcodesManager.staffCards.map(x => x.clone()));

    this.subscriptionBarcodeInfosUpdated = this.localBarcodesManager.staffCardsUpdated.subscribe(
      () => reload()
    );
    reload();
    this.subscriptionTeachingBarcodeChange = this.appStateService.appState.teachingBarcodeChange.subscribe(
      x => this.teachBarcodeChange(x)
    );

    this._teachingMode = this.appStateService.appState.isTeachingMode;
    this.subscriptionTeachingModeChange = this.appStateService.appState.teachingModeChange.subscribe(
      x => this.teachingModeChange(x)
    );
  }

  ngOnDestroy(): void {
    this.subscriptionBarcodeInfosUpdated.unsubscribe();
    this.subscriptionTeachingBarcodeChange.unsubscribe();
  }

  private reload(staffCards: StaffCard[]) {
    if (this._modalShown) {
      return;
    }

    const f = () => {
      this.staffCardsBackup = staffCards.map(x => x.clone());
      this.reset();
    };

    const barcodesChanged = !StaffCard.areEqual(
      this.staffCards,
      staffCards
    );

    if (!(this.dirty && barcodesChanged)) {
      f();
      return;
    }
  }

  save() {
    this.staffCardsBackup = this.staffCards.map(x => x.clone());
    this.reset();
    this.backendApiService.backendApi
      .saveLocalBarcodes(this.staffCards)
      .subscribe(() => {}, e => console.error(e));
  }

  get saveDisabled(): boolean {
    return this._saveDisabled;
  }

  reset() {
    this.staffCards = this.staffCardsBackup.map(x => x.clone());
    if (this.barcodeComponents) {
      this.barcodeComponents.forEach(x => x.reset());
    }
    this.changed = false;
  }

  sync() {
    this.backendApiService.backendApi
    .updateStaffCardsFromServer()
    .subscribe(() => {}, e => console.error(e));
  }

  private formGroupChanged(formGroup: FormGroup) {
    if (this.barcodeComponents == null) {
      return;
    }

    this.barcodeComponents
      .filter(x => x.formGroup !== formGroup)
      .forEach(x => x.barcode.updateValueAndValidity({ emitEvent: false }));

    this.updateUiState();
  }

  private updateUiState() {
    if (!this.barcodeComponents) {
      return;
    }

    const isProperyOn = (name: string): boolean => {
      return this.barcodeComponents.reduce((p, c) => {
        const r = p || c[name];

        return r;
      }, false);
    };

    this.dirty = isProperyOn('dirty') || this.changed;
    this.invalid = isProperyOn('invalid');
    this._saveDisabled = this.invalid || !this.dirty;
  }

  addClick() {
    this.addBarcode();
  }

  enableAllClick() {
    if (this.barcodeComponents != null) {
      this.barcodeComponents.forEach(x => x.enable());
    }
  }

  disableAllClick() {
    if (this.barcodeComponents != null) {
      this.barcodeComponents.forEach(x => x.disable());
    }
  }

  deleteAllClick() {
    this.staffCards = [];
    this.changed = true;
  }

  private addBarcode(barcode: string = '') {
    const card = new StaffCard(barcode, 1);
    card.isLocal = this.isLocal;
    this.staffCards.unshift(card);

    setTimeout(() => {
      this.barcodeComponents.first.setFocus();
      this.updateUiState();
    }, 0);
    this.changed = true;
  }

  protected deleteClick(x: CardComponent) {
    const index = this.staffCards.indexOf(x.barcodeInfo, 0);
    if (index < 0) {
      return;
    }
    this.staffCards.splice(index, 1);
    this.changed = true;
  }

  protected isBarcodeAlreadyInUse(barcode: string): boolean {
    return this.getBarcodeCount(barcode) > 1;
  }

  private getBarcodeCount(barcode: string): number {
    return this.getBarcodeComponents(barcode).length;
  }

  private getBarcodeComponents(barcode: string): CardComponent[] {
    if (!barcode || !this.barcodeComponents) {
      return [];
    }

    return this.barcodeComponents.filter(x => x.barcode.value === barcode);
  }

  saveToFileClick() {
    const blob = new Blob([JSON.stringify(this.staffCards.map(x => x.toJson()), null, 4)], {
      type: 'text/csv'
    });

    FileUtility.saveToFile('cards.json', blob);
  }

  loadFromFileClick() {
    FileUtility.selectFiles('.json', files => {
      for (let index = 0; index < files.length; index++) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const json = JSON.parse(reader.result as string);
            this.staffCards = StaffCard.fromJsonWithArray(json);
            this.changed = true;
          } catch (e) {
            console.error(e);
          }
        };
        reader.readAsText(files[index]);
      }
    });
  }

  protected get changed(): boolean {
    return this._changed;
  }

  protected set changed(x: boolean) {
    this._changed = x;
    this.updateUiState();
  }

  private teachBarcodeChange(barcode: string) {
    if (!this.teachingMode) {
      return;
    }

    const components = this.getBarcodeComponents(barcode);
    if (components.length <= 0) {
      this.addBarcode(barcode);
    } else {
      components.forEach(x => x.onBarcodeRead());
    }
  }

  private teachingModeChange(x: boolean) {
    this._teachingMode = x; // Important
  }

  get teachingMode(): boolean {
    return this._teachingMode;
  }

  set teachingMode(x: boolean) {
    if (this._teachingMode === x) {
      return;
    }

    this._teachingMode = x;
    this.backendApiService.backendApi
      .setTeachingMode(x)
      .subscribe(() => {}, e => console.error(e));
  }
}
