import * as _ from 'lodash';
import { Component, ViewChild } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { AppStateService } from 'src/app/services/app-state.service';
import { AppState } from 'src/app/models/app-state';
import { RandomWinnerTracker } from 'src/app/models/random-winner/random-winner-tracker';
import { Router } from '@angular/router';
import { RandomWinnerHistoryComponent } from 'src/app/components/random-winner-history/random-winner-history.component';

@Component({
  selector: 'app-random-winner-page',
  templateUrl: 'random-winner-page.component.html',
  styleUrls: ['random-winner-page.component.scss']
})
export class RandomWinnerPageComponent {
  backendApi: IBackendApi;
  appState: AppState;
  @ViewChild(RandomWinnerHistoryComponent) historyComponent: RandomWinnerHistoryComponent;


  constructor(backendApiService: BackendApiService,
    appStateService: AppStateService,
    private router: Router) {
    this.appState = appStateService.appState;
    this.backendApi = backendApiService.backendApi;
  }

  reset() {
    this.backendApi.randomWinnerReset().subscribe(() => { },
      e => console.error(e)
    );
  }

  get trackerEnter(): RandomWinnerTracker {
    return _.get(this.appState, 'randomWinner.trackerEnter');
  }

  get trackerExit(): RandomWinnerTracker {
    return _.get(this.appState, 'randomWinner.trackerExit');
  }

  get probability(): number {
    return _.get(this.appState, 'randomWinner.probability');
  }

  changeConfiguration() {
    this.router.navigate(['configuration'], { fragment: 'random_winner' });
  }

  saveToCsvFile() {
    this.historyComponent.saveToCsvFile();
  }

  isHistoryEmpty() {
      return this.historyComponent && this.historyComponent.isEmpty;
  }
}
