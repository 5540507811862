import { Bridge } from '../bridge/bridge';
import { BackendHelper } from '../backend-helper';
import { PythonConvertor } from '../python-convertor';

export class DeviceStatus extends Bridge<boolean> {
  id: string;
  name: string;
  type: string;
  state: string;
  failure: string;
  isOptional: boolean;
  isSimulator: boolean;
  objectId: string;

  constructor(id: string) {
    super(false);
    this.id = id;
  }

  updateFromJson(json: any) {
    this.name = json.name;
    this.type = json.type;
    this.state = json.state;
    this.failure = json.failure;
    this.updateValueFromJson(Boolean(json.failure));
    this.isOptional = json.is_optional;
    this.isSimulator = json.is_simulator;
    this.objectId = json.object_id;
  }

  get exec(): string {
    const method = BackendHelper.getPathDeviceMethod('device_manager', 'get_device_by_id');
    return `${method}('${this.id}').set_simulated_failure(${PythonConvertor.toBoolean(this.value)})`;
  }
}

export class DevicesStatus {
  _map = new Map<string, DeviceStatus>();

  getItem(id: string): DeviceStatus {
    return this._map.get(id);
  }

  get items(): DeviceStatus[] {
    return Array.from(this._map.values());
  }

  get is_devices_exist() {
    return this.items.length > 0;
  }

  updateItemFromJson(json: any) {
    const id = json.id;
    const item = this.getItem(id);
    if (item != null) {
      item.updateFromJson(json);
    }
  }

  updateFromJson(json: any) {
    this._map.clear();
    json.forEach(x => {
      const id = x.id;
      let item = this.getItem(id);
      if (item == null) {
        item = new DeviceStatus(id);
        this._map.set(id, item);
      }
      item.updateFromJson(x);
    });
  }
}
