export class Hint {
  name: string;
  messages: string[];
  danger: boolean;
  hideWhenClean: boolean;

  constructor(name: string, danger = false, hideWhenDisabled = false) {
    this.name = name;
    this.danger = danger;
    this.hideWhenClean = hideWhenDisabled;
  }

  get clean(): boolean {
    return !(this.messages != null && this.messages.length > 0);
  }

  get hidden(): boolean {
    return this.hideWhenClean && this.clean;
  }
}
