import { StaffCard } from 'fmcu-core-ng';
import { Subject } from 'rxjs/Subject';

export class LocalBarcodesManager {
  staffCards: StaffCard[] = [];
  staffCardsUpdated = new Subject();

  updateFromJson(json: any) {
    this.staffCards = StaffCard.fromJsonWithArray(json);
    this.staffCardsUpdated.next();
  }
}
