import { Leg } from './leg';

export class Node {
  id: number;
  x: number;
  y: number;
  radius: number;
  isDeleted = false;
  legs: Leg[] = [];

  constructor(id: number, x: number, y: number, radius: number) {
    this.id = id;
    this.x = x;
    this.y = y;
    this.radius = radius;
  }
}
