import { NgModule } from '@angular/core';
import { ConfigurationFormComponent } from './components/configuration-form/configuration-form.component';
import { FormlyFieldSwitchComponent } from './components/formly-field-switch/formly-field-switch.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { OperationComponent } from './components/operation/operation.component';
import { HintsComponent } from './components/hints/hints.component';
import { FileSelectionComponent } from './components/file-selection/file-selection.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigurationService } from './services/configuration.service';
import { ScrollTopComponent } from 'src/app/components/common/scroll-top/scroll-top.component';
import { RepeatTypeComponent } from './components/repeat-type/repeat-type.component';
import { InformationPopupComponent } from './components/information-popup/information-popup.component';
import { LoadingComponent } from 'src/app/components/loading/loading.component';
import { GateSignalsListPopupComponent } from './components/gate-signals-list-popup/gate-signals-list-popup.component';
import { GateSignalsTableComponent } from './components/gate-signals-table/gate-signals-table.component';
import { InstallationExecutableDeviceTypeComponent } from './components/installation-executable-device-type/installation-executable-device-type.component';
import { FirstSetupStepsNavigationComponent } from './components/first-setup-steps-navigation/first-setup-steps-navigation.component';
import { UpdateSoftwareComponent } from './components/update-software/update-software.component';
import { LocalizedDatePipeModule } from 'projects/fmcu-core-ng/src/lib/pipes/localized-date-pipe.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'switch', component: FormlyFieldSwitchComponent },
        { name: 'repeat', component: RepeatTypeComponent }
      ]
    }),
    FormlyBootstrapModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    NgbModule,
    UiSwitchModule,
    LocalizedDatePipeModule
  ],
  declarations: [
    HintsComponent,
    OperationComponent,
    ConfigurationFormComponent,
    FormlyFieldSwitchComponent,
    FileSelectionComponent,
    ScrollTopComponent,
    RepeatTypeComponent,
    InformationPopupComponent,
    LoadingComponent,
    GateSignalsListPopupComponent,
    GateSignalsTableComponent,
    InstallationExecutableDeviceTypeComponent,
    FirstSetupStepsNavigationComponent,
    UpdateSoftwareComponent,
  ],
  exports: [
    HintsComponent,
    ScrollTopComponent,
    OperationComponent,
    ConfigurationFormComponent,
    FileSelectionComponent,
    InformationPopupComponent,
    LoadingComponent,
    GateSignalsListPopupComponent,
    GateSignalsTableComponent,
    InstallationExecutableDeviceTypeComponent,
    FirstSetupStepsNavigationComponent,
    UpdateSoftwareComponent,
  ],
  providers: [
    ConfigurationService,
  ]
})
export class SharedModule {}
