import { RandomWinnerTracker } from './random-winner-tracker';
import { JsonObject, JsonProperty } from 'json2typescript';
import { RandomWinnerTrackerConverter } from './random-winner-tracker-convertor';

@JsonObject
export class RandomWinner {
  @JsonProperty('tracker_enter', RandomWinnerTrackerConverter, String)
  trackerEnter: RandomWinnerTracker = undefined;
  @JsonProperty('tracker_exit', RandomWinnerTrackerConverter, String)
  trackerExit: RandomWinnerTracker = undefined;
  @JsonProperty('probability', Number)
  probability: number = undefined;
}
