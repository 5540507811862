import { GateType, parseGateType } from 'fmcu-core-ng';

export enum GateUnitFeature {
  DoorSelection,
}

export class Gate {
  minVersionDoorSelection: string;
  name: string;
  gateType: GateType;


  updateFromJson(x: any) {
    if (!x) {
      return;
    }
    this.minVersionDoorSelection = x.min_version_door_selection;
    this.name = x.name;
    this.gateType =  parseGateType(x.gate_type);
  }
}
