import { baseHttpUrl } from '../configuration';

export class FileDescriptor {
  fileName: string;
  category: string;
  webPath: string;

  constructor(fileName: string, category: string, webPath: string) {
    this.fileName = fileName;
    this.category = category;
    this.webPath = webPath;
  }

  get url(): string {
    return `${baseHttpUrl}/${this.webPath}`;
  }

  static fromJson(json: any) {
    return new FileDescriptor(json.file_name, json.category, json.web_path);
  }
}
