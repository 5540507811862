import { GateSide } from '../gate-side';
import { Bridge } from './bridge';
import { BackendHelper } from '../backend-helper';
import { PythonConvertor } from '../python-convertor';
import { HtmlColor } from '../../modules/shared/models/html-color';

export enum GpioContactEnum {
  Red = 'Red',
  Green = 'Green',
  Power = 'Power',
  ExtenalLight = 'ExternalLight',
}

export class GpioContact extends Bridge<boolean> {
  private gateSide: GateSide;
  private _gpioContactEnum: GpioContactEnum;

  constructor(gateSide: GateSide, gpioContactEnum: GpioContactEnum) {
    super(false);
    this._gpioContactEnum = gpioContactEnum;
    this.gateSide = gateSide;
  }

  get gpioContactEnum(): GpioContactEnum {
    return this._gpioContactEnum;
  }

  get color(): string {
    switch (this.gpioContactEnum) {
      case GpioContactEnum.Red:
        return HtmlColor.Red;
      case GpioContactEnum.Power:
        return HtmlColor.Blue;
      default:
        return '';
    }
  }

  get exec(): string {
    const side = this.gateSide === GateSide.Entry ? 'entry' : 'exit';
    const method = BackendHelper.getPathDeviceMethod(
      `gpio_${side}`,
      `${this.pythonName}`
    );
    return `${method} = ${PythonConvertor.toBoolean(this.value)}`;
  }

  get pythonName(): string {
    const name = this._gpioContactEnum.split(/(?=[A-Z])/).join('_').toLocaleLowerCase();
    return `is_${name}`;
  }

  get apiName(): string {
    return `is${this._gpioContactEnum}`;
  }
}

export class Gpio {
  private _gpioContacts = new Map<GpioContactEnum, GpioContact>();

  constructor(gateSide: GateSide) {
    Object.keys(GpioContactEnum).forEach(x =>
      this._gpioContacts.set(
        GpioContactEnum[x],
        new GpioContact(gateSide, GpioContactEnum[x])
      )
    );
  }

  get gpioContacts(): GpioContact[] {
    return Array.from(this._gpioContacts.values());
  }

  getGpioContact(x: GpioContactEnum): GpioContact {
    return this._gpioContacts.get(x);
  }
}
