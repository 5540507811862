import { Component } from '@angular/core';
import { AppStateService } from '../../services/app-state.service';
import { FileDescriptor } from '../../models/file-descriptor';

@Component({
  selector: 'app-download',
  templateUrl: 'download.component.html',
  styleUrls: ['download.component.scss']
})
export class DownloadComponent {
  constructor(public appStateService: AppStateService) {}

  get downloadableFiles(): FileDescriptor[] {
    const downloadableFiles = this.appStateService.appState.downloadableFiles;
    return downloadableFiles == null ? [] : downloadableFiles;
  }
}
