import { Bridge } from './bridge';
import { BackendHelper } from '../backend-helper';
import { PythonConvertor } from '../python-convertor';
import { environment } from '../../../environments/environment';
import { baseHttpUrl } from '../../configuration';
import { Audio } from '../audio';

export class AudioPlayer extends Bridge<string> {
  constructor() {
    super('');
  }

  play(audio: Audio) {
      console.log(audio.pathToFile);
      const audioElement = document.createElement('audio');
      audioElement.onended = () => {
        const method = BackendHelper.getPathDeviceMethod('audio_player', 'sim_play_audio_finish');
        this.backendStatementCreated.next(`${method}('${audio.fileName}')`);
      };
      audioElement.pause();
      audioElement.currentTime = 0;
      audioElement.volume = audio.volume;
      audioElement.setAttribute('src', '');
      const src = `${baseHttpUrl}${audio.pathToFile}`;
      audioElement.setAttribute('src', src);
      const playPromise = audioElement.play();
  }
}
