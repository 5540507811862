import { Component, Input, ViewEncapsulation } from '@angular/core';
import { GateUnit } from 'src/app/models/gate-unit/gate-unit';
import { GateContact } from 'src/app/models/gate-contact';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { PythonConvertor } from 'src/app/models/python-convertor';
import { AppStateService } from 'src/app/services/app-state.service';
import { GateUnitPosition } from 'src/app/models/gate-unit/gate-unit-position';

@Component({
  selector: 'app-gate-unit',
  templateUrl: 'gate-unit.component.html',
  styleUrls: ['gate-unit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GateUnitComponent {
  @Input() gateUnit: GateUnit;

  constructor(private backendApiService: BackendApiService,
    appStateService: AppStateService) { }

  get cssClass(): string {
    return this.gateUnit && this.gateUnit.isSimulator ? 'gate-unit' : 'inactive-background gate-unit';
  }

  getIsGateContactEnabled(gateContact: GateContact): boolean {
    return this.gateUnit && this.gateUnit.isSimulator && gateContact.simulatable;
  }

  onContactValueChanged(contact: GateContact, x: boolean) {
    const command = `root.gate.sim_gate_input_contact_set(${this.gateUnit.unit}, '${contact.name}', ${PythonConvertor.toBoolean(x)})`;
    this.backendApiService.backendApi.exec(command);
  }

  get someCustomActions(): boolean {
    return this.gateUnit.gateContacts.some(x => x.actionTag != null);
  }

  get positionName(): string {
    const gateUnit = this.gateUnit;
    if (!gateUnit || gateUnit.position == null) {
      return null;
    }
    return GateUnitPosition[gateUnit.position];
  }
}
