import {
  JsonProperty,
} from 'json2typescript';

export class LightBar {
  @JsonProperty('sensors_h_entry', [Boolean])
  sensorsHEntry: boolean[] = undefined;

  @JsonProperty('sensors_h_exit', [Boolean])
  sensorsHExit: boolean[] = undefined;

  @JsonProperty('sensors_h_middle', [Boolean])
  sensorsHMiddle: boolean[] = undefined;

  @JsonProperty('seats', [Boolean])
  seats: boolean[] = undefined;

  @JsonProperty('is_simulator', Boolean)
  isSimulator: boolean = undefined;

  @JsonProperty('is_stub', Boolean)
  isStub: boolean = undefined;

  @JsonProperty('sensors_v_entry_count', Number)
  sensorsVEntryCount: number = undefined;

  @JsonProperty('sensors_v_exit_count', Number)
  sensorsVExitCount: number = undefined;

  @JsonProperty('max_v_entry_index', Number)
  maxVEntryIndex: number = undefined;

  @JsonProperty('max_v_exit_index', Number)
  maxVExitIndex: number = undefined;

  @JsonProperty('danger_zone_size_entry', Number)
  dangerZoneSizeEntry: number = undefined;

  @JsonProperty('danger_zone_size_exit', Number)
  dangerZoneSizeExit: number = undefined;
}
