import { Component, OnInit, Input } from '@angular/core';
import { GateSignal } from 'src/app/models/gate-signal/gate-signal';
import { AppStateService } from 'src/app/services/app-state.service';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';

@Component({
  selector: 'app-gate-signals-table',
  templateUrl: './gate-signals-table.component.html',
  styleUrls: ['./gate-signals-table.component.css']
})
export class GateSignalsTableComponent implements OnInit {

  @Input()
  gateSignals: GateSignal[];

  @Input()
  tableCaption: string;

  private backendApi: IBackendApi;

  constructor(
    public appStateService: AppStateService,
    private backendApiService: BackendApiService,
  ) {
    this.backendApi = backendApiService.backendApi;
   }


  ngOnInit(): void {
  }

  getIndexOfGateSignal(gateSignal: GateSignal) {
    if (!this.appStateService || !this.appStateService.appState || !this.appStateService.appState.gateSignalsManager) {
      return null;
    }

    return this.appStateService.appState.gateSignalsManager.getIndexByGateSignal(gateSignal);
  }

  getCssClassSignalGate(gateSignal: GateSignal): string {
    if (gateSignal && gateSignal.enabled) {
      return gateSignal.count ? 'signalOn' : 'signalOff';
    }

    return 'signalDisabled';
  }

  onSignalClick(gateSignal: GateSignal, event) {
    this.backendApi.setGateSignal(gateSignal.objectId, this.getIncrementedCount(gateSignal.count, event)).subscribe(
      () => {

      }, e => console.error(e));
  }

  private getIncrementedCount(count: number, event): number {
    if (!event.ctrlKey) {
      return count + 1;
    }

    if (count > 0) {
      count -= 1;
    }

    return count;
  }

}
