import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState, StateChangeFlags } from './models/app-state';
import { AuthService } from './services/auth.service';
import { AppStateService } from './services/app-state.service';
import { Title } from '@angular/platform-browser';
import { RolesService } from './services/backend-api/roles.service';
import { LanguageService } from './services/language.service';
import { BackendApiService } from './services/backend-api/backend-api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FMCU';
  getTranslationsSubscribe: Subscription;

  constructor(
    private authService: AuthService,
    private appService: AppStateService,
    private titleService: Title,
    private rolesService: RolesService,
    private languageService: LanguageService,
    private backendApiService: BackendApiService,
  ) {
    /* tslint:disable:no-bitwise */
    appService.appState.stateChanged.filter((x) => (x | StateChangeFlags.Confguration) > 0).subscribe((x) => this.updateTitle(x));
  }

  ngOnInit() {
    this.getTranslationsUnsubscribe();
    this.getTranslationsSubscribe = this.backendApiService.backendApi.getTranslations().subscribe(x => {
      if (x && x.translations) {
        this.languageService.loadTranslations(x.translations);
      }
    });
  }

  ngOnDestroy() {
    this.getTranslationsUnsubscribe();
  }

  getTranslationsUnsubscribe() {
    if (this.getTranslationsSubscribe) {
      this.getTranslationsSubscribe.unsubscribe();
      this.getTranslationsSubscribe = null;
    }
  }

  get appState(): AppState {
    return this.appService.appState;
  }

  get footerInfo(): string {
    return `${this.appState.boardInfo} ${this.appState.version} ${this.appState.settings}`;
  }

  get isAuthenticated(): boolean {
    return this.authService.isAuthenticated;
  }

  private updateTitle(x) {
    this.titleService.setTitle(_.get(this.appState, 'configuration.general.general.gate_name', 'FMCU'));
  }

  get isSetupStepsActive(): boolean {
    return this.rolesService.isSetupStepsActive;
  }
}
