import { JsonProperty } from 'json2typescript';
import { JsonConvertorUtility } from 'fmcu-core-ng';
import { Subject } from 'rxjs';

export interface LedPlayerUpdateServiceJson {
  player_name: string;
  is_ready_for_update: boolean;
  is_updating: boolean;
  message: string;

}

export class LedPlayerUpdateService {
  static jsonKey = 'led_player_update_service';
  subjectStateChanged = new Subject();

  @JsonProperty('player_name', String)
  playerName: string = undefined;

  @JsonProperty('is_ready_for_update', Boolean)
  isReadyForUpdate: boolean = undefined;

  @JsonProperty('is_updating', Boolean)
  isUpdating: boolean = undefined;

  @JsonProperty('message', String)
  message: string = undefined;

  @JsonProperty('error', String)
  error: string = undefined;

  updateFromJson(json: LedPlayerUpdateServiceJson) {
    const x = JsonConvertorUtility.deserialize(json, LedPlayerUpdateService);
    this.playerName = x.playerName;
    this.isReadyForUpdate = x.isReadyForUpdate;
    this.isUpdating = x.isUpdating;
    this.message = x.message;
    this.error = x.error;
    this.subjectStateChanged.next();
  }
}
