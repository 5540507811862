import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { FileSelectionComponent } from '../../modules/shared/components/file-selection/file-selection.component';

const COOKIE_KEY = 'files-tab-id';

@Component({
  selector: 'app-files',
  templateUrl: './files-page.component.html',
  styleUrls: ['./files-page.component.css']
})

export class FilesPageComponent implements AfterViewInit {
  @ViewChild(FileSelectionComponent) fileSelectionComponent;
  _activeId = 'download';

  constructor(private cookieService: CookieService) { }

  ngAfterViewInit(): void {
    const tabId = this.cookieService.get(COOKIE_KEY);
    if (tabId != null) {
      setTimeout(() => this._activeId = tabId, 1);
    }
  }

  get activeId(): string {
    return this._activeId;
  }

  set activeId(x: string) {
    this._activeId = x;
    this.cookieService.put(COOKIE_KEY, x);
  }

  get uploadHandler() {
    return (file: File) => { };
  }

  get canUpload() {
    return this.fileSelectionComponent != null && this.fileSelectionComponent.fileName != null;
  }

  onTabChange(event: any) {
    this.cookieService.put(COOKIE_KEY, event.nextId);
  }
}
