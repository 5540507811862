import { Component, HostListener } from '@angular/core';

@Component({
    selector: 'app-scroll-top',
    templateUrl: 'scroll-top.component.html',
    styleUrls: ['scroll-top.component.scss']
})
export class ScrollTopComponent {
  scrolled: boolean;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }

  }

  scrollToTop() {
    window.location.hash = '';
    window.scrollTo(0, 0);
  }
}
