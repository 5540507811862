import { Bridge } from './bridge';
import { BackendHelper } from '../backend-helper';

export enum GateModeManagerStateEnum {
  Weac = 'Weac',
  Normal = 'Normal',
  Locked = 'Locked',
  LockedEntry = 'LockedEntry',
  LockedExit = 'LockedExit',
  Winner = 'Winner',
  Service = 'Service',
  FireAlarm = 'FireAlarm',
  Emergency = 'Emergency',
  SelfTest = 'SelfTest',
  Off = 'Off'
}


export class GateModeManagerState extends Bridge<GateModeManagerStateEnum> {
  constructor() {
    super(GateModeManagerStateEnum.Normal);
  }

  get titlesWithValues(): any[] {
    return Object.keys(GateModeManagerStateEnum)
      .map(x => x === 'FireAlarm' ? 'Fire' : x)
      .map(x => [x, x === this.value]);
  }

  get canToNormal(): boolean {
    return this.isInMode(...this.getOtherNormalModes(GateModeManagerStateEnum.Normal));
  }

  get canToLocked(): boolean {
    return this.isInMode(...this.getOtherNormalModes(GateModeManagerStateEnum.Locked));
  }

  get canToLockedEntry(): boolean {
    return this.isInMode(...this.getOtherNormalModes(GateModeManagerStateEnum.LockedEntry));
  }

  get canToLockedExit(): boolean {
    return this.isInMode(...this.getOtherNormalModes(GateModeManagerStateEnum.LockedExit));
  }

  get canToService(): boolean {
    return this.isInMode(...this.getOtherNormalModes(GateModeManagerStateEnum.Service));
  }

  get canToSelfTest(): boolean {
    return this.isInMode(...this.getOtherNormalModes(GateModeManagerStateEnum.SelfTest));
  }

  get canToOff(): boolean {
    return this.isInMode(...this.getOtherNormalModes(GateModeManagerStateEnum.Off));
  }

  private getOtherNormalModes(mode: GateModeManagerStateEnum): GateModeManagerStateEnum[] {
    return [
      GateModeManagerStateEnum.Normal,
      GateModeManagerStateEnum.Locked,
      GateModeManagerStateEnum.LockedEntry,
      GateModeManagerStateEnum.LockedExit,
      GateModeManagerStateEnum.Service,
      GateModeManagerStateEnum.SelfTest,
      GateModeManagerStateEnum.Off,
    ].filter(x => x !== mode);
  }

  get canResetWinner(): boolean {
    return this.isInMode(
      GateModeManagerStateEnum.Winner,
    );
  }

  private isInMode(...states: GateModeManagerStateEnum[]): boolean {
    return states.some(x => GateModeManagerStateEnum[x] === this.value);
  }
}
