import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { LazyExecutor } from '../lazy-executor';
import { LightBar } from './light_bar';
import { JsonConvertorUtility } from 'fmcu-core-ng';
import { Subject } from 'rxjs/Subject';

export class LightBarController {
  lightBar: LightBar;
  valueChanged = new Subject();
  private backendApi: IBackendApi;
  private executor = new LazyExecutor();

  constructor(backendApi: IBackendApi) {
    this.backendApi = backendApi;
  }

  simulationVEntryOn: boolean;
  simulationVExitOn: boolean;

  simulateSensors(hEntryHValues: boolean[], hExitHValues: boolean[], isVEntry: boolean, isVExit: boolean, fSuccess: any) {
    const scope = this;
    this.executor.execute(() => scope.backendApi.lightBarSimulateSensors(hEntryHValues, hExitHValues, isVEntry, isVExit).subscribe(
      () => fSuccess(),
      e => console.error(e)
    ));
  }

  updateFromJson(json: any) {
    this.lightBar = JsonConvertorUtility.deserialize(json, LightBar);
    this.valueChanged.next();
  }
}
