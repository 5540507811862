import { Component, OnInit, OnDestroy } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { UpdateSoftwareStatus } from 'src/app/models/status/update-software-status';

@Component({
  selector: 'app-update-software',
  templateUrl: './update-software.component.html',
  styleUrls: ['./update-software.component.css']
})
export class UpdateSoftwareComponent implements OnInit, OnDestroy {
  canUploadSoftwareUpdate = true;
  uploadUpdateSoftwareError = '';
  status: UpdateSoftwareStatus;
  intervalId: NodeJS.Timeout;
  isFileLoading = false;


  constructor(
    private backendApiService: BackendApiService,
  ) { }

  ngOnInit(): void {
    this.intervalId = setInterval(() => this.updateStatus(), 2000);
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateStatus() {
    this.backendApiService.backendApi.updateSoftwareGetStatus().subscribe((x) => {
      if (!this.status) {
        this.status = new UpdateSoftwareStatus();
      }

      this.status.updateFromJson(x.updateSoftwareStatus);
    });
  }

  getUpdateSoftwareUploadHandler() {
    return (file: File) => {
      const reader = new FileReader();
      reader.onload = () => {
        this.isFileLoading = true;
        this.uploadUpdateSoftwareError = '';
        this.backendApiService.backendApi
          .uploadUpdateSoftware(file[0].name, reader.result as string)
          .subscribe(
              () => {
                this.isFileLoading = false;
              },
            e => {
              this.isFileLoading = false;
              this.uploadUpdateSoftwareError = this.fromError(e);
            }
          );
      };
      reader.readAsDataURL(file[0]);
    };
  }

  fromError(e) {
    return e ? e.statusText || e.message : null;
  }

}
