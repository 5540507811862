import { Component, Input } from '@angular/core';
import { DisplaysStatus } from '../../../models/status/displays-status';

@Component({
      selector: 'app-status-displays',
    templateUrl: 'status-displays.component.html',
    styleUrls: ['status-displays.component.scss']
})
export class StatusDisplaysComponent {
    @Input() displaysStatus: DisplaysStatus;
}
