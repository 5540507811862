import { Highlighter } from 'fmcu-core-ng';

export enum CompositStatusItemState {
  OK,
  NOK,
  Warning
}

export class CompositStatusItem extends Highlighter {
  name: string;
  info: string;
  value: string;
  dateTime: Date;
  state = CompositStatusItemState.NOK;

  constructor(name: string) {
    super();
    this.name = name;
  }

  get cssClass(): string {
    switch (this.state) {
      case CompositStatusItemState.OK:
        return 'fa fa-thumbs-up color-green';
      case CompositStatusItemState.Warning:
        return 'fa fa-exclamation color-orange';
      default:
        return 'fa fa-thumbs-down color-red';
    }
  }

  updateFromJson(json: any) {
    this.dateTime = new Date(json.dateTime);
    this.name = json.name;
    this.info = json.info;
    this.state = json.state;
    this.value = json.value;
    this.highlight();
  }
}

export class CompositStatus {
  private _mapStatusItems = new Map<string, CompositStatusItem>();

  getItem(name: string): CompositStatusItem {
    return this._mapStatusItems.get(name);
  }

  get items(): CompositStatusItem[] {
    return Array.from(this._mapStatusItems.values());
  }

  updateFromJson(json: any) {
    this._mapStatusItems.clear();
    json.forEach(x => {
      const item = x.status_item;
      const statusItem = new CompositStatusItem(item.name);
      statusItem.updateFromJson(item);
      this._mapStatusItems.set(statusItem.name, statusItem);
    });
  }

  updateStatusItemFromJson(json: any) {
    const statusItem = this.getItem(json.name);
    if (statusItem == null) {
      console.error(
        `updateStatusItemFromJson. item not found for name '${json.name}'`
      );
      return;
    }
    statusItem.updateFromJson(json);
  }
}
