export class Cookie {
  static get(name: string): string {
    const cookies: Array<string> = document.cookie.split(';');
    const cookieName = `${name}=`;

    for (let i = 0; i < cookies.length; i += 1) {
      const c = cookies[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return null;
  }

  static remove(name) {
    this.put(name, '', -365);
  }

  static put(name: string, value: string, expireDays = 1, path = '') {
    const date = new Date();
    date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    const cookiePath = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cookiePath}`;
  }
}
