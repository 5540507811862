import { Injectable, Injector } from '@angular/core';
import { IBackendApi} from './backend-api.interface';
import { environment } from '../../../environments/environment';
import { BackendApiHttpService } from './backend-api-http.service';

@Injectable()
export class BackendApiService {
  private _backendApi: IBackendApi;
  constructor(protected injector: Injector) {
    const isApiHttp = environment.unconditionallyUseApiHttp || environment.production;
    const service = isApiHttp
      ? BackendApiHttpService
      : BackendApiHttpService; // Replace with a simulator when it is needed
    this._backendApi = injector.get(service);
  }

  get backendApi(): IBackendApi {
    return this._backendApi;
  }
}
