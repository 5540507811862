import { map, tap, filter, retryWhen, catchError } from 'rxjs/operators';
import 'rxjs/add/operator/delay';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { IBackendEvents } from './backend-events-interface';
import { Subject } from 'rxjs/Subject';
import { baseWsUrl } from '../../configuration';
import { webSocket } from 'rxjs/webSocket';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { baseHttpUrl } from '../../configuration';

@Injectable()
export class BackendEventsWebsocketService implements IBackendEvents {
  private _messages: Observable<any>;
  private _isConnected = null;
  private _fmcuDate: Date;
  connectionChanged = new Subject<boolean>();
  fmcuDateChanged = new Subject<Date>();

  constructor() {
    const subject = webSocket(`${baseWsUrl}/socket-ui`);
    this._messages = subject.pipe(
      catchError((error: HttpErrorResponse) => {
        const msg = `${error.status} ${error.statusText} -  ${error.url}`;
        this.isConnected = false;
        return throwError(new Error(msg));
      }),
      retryWhen(errors => errors.delay(1000)),
      tap(x => {
        this.isConnected = true;
        if (x.global_error) {
          window.location.href = `${baseHttpUrl}/error`;
        }
        this.onPing(x.ping);
      }));
  }

  get isConnected(): boolean {
    return this._isConnected;
  }

  set isConnected(value: boolean) {
    if (this._isConnected === value) {
      return;
    }

    this._isConnected = value;
    console.log(`isConnected: ${value}`);

    this.connectionChanged.next(value);
  }

  get messages(): Observable<any> {
    return this._messages;
  }

  get fmcuDate(): Date {
    return this._fmcuDate;
  }

  set fmcuDate(x: Date) {
    this._fmcuDate = x;
    this.fmcuDateChanged.next(x);
  }

  private onPing(dateTime: any) {
    if (!dateTime) {
      return;
    }
    this.fmcuDate = new Date(dateTime);
  }
}
