import { GateSide } from './gate-side';

export class BackendHelper {
  static getPathDevice(deviceName: string): string {
    return `root.${deviceName}`;
  }

  static getPathDeviceMethod(deviceName: string, methodName: string): string {
    return `${BackendHelper.getPathDevice(deviceName)}.${methodName}`;
  }

  static getGateSideName(gateSide: GateSide): string {
    return gateSide === GateSide.Entry ? 'entry' : 'exit';
  }

  static getPathReader(readerIndex: number): string {
    return `root.readers[${readerIndex}]`;
  }

  static getPathReaderMethod(readerIndex: number, methodName: string): string {
    return `${BackendHelper.getPathReader(readerIndex)}.${methodName}`;
  }
}
