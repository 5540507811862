export enum GateDoorEnum {
  Unit1,
  Unit2
}

export class GateDoor {
  id: string;
  x: number;
  y: number;
  gateDoorEnum: GateDoorEnum;
  getCssClass: () => string;


  constructor(id, x: number, y: number, gateDoorEnum: GateDoorEnum, getCssClass: () => string = null) {
    this.id = id;
    this.x = x;
    this.y = y;
    this.gateDoorEnum = gateDoorEnum;
    this.getCssClass = getCssClass;
  }

  get cssClass(): string {
    return this.getCssClass();
  }
}
