import * as _ from 'lodash';
import { Component } from '@angular/core';
import { ConfigurationsConfig, Dispatcher, GateControl, GateType, IConfigurationInfo } from 'fmcu-core-ng';
import { OnInit } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { AppAvailabilityState, AppStateService } from '../../services/app-state.service';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { StateChangeFlags } from 'src/app/models/app-state';
import { GateAction, GateStatus } from 'fmcu-core-ng';
import * as momentNs from 'moment';
import { GateControlPanel, ModalComponent } from 'fmcu-core-ng';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatisticsCategory } from 'src/app/models/statistics';
import { filter } from 'rxjs/operators';
import { AppEvent, AppEventFlag } from 'src/app/models/app-event';
import { Dict } from 'src/app/models/interfaces';
import { thresholdFreedmanDiaconis } from 'd3-array';
const moment = momentNs;

@Component({
  selector: 'app-gate-compound-view',
  templateUrl: 'gate-compound-view.component.html',
  styleUrls: ['gate-compound-view.component.scss']
})
export class GateCompoundViewComponent implements OnInit, OnDestroy {
  svgWidth = 200;
  entryCount = 0;
  exitCount = 0;
  gate: GateControl;
  status: GateStatus;
  panel: GateControlPanel;
  from: Date;
  disabled = false;
  private timer: number;
  private statisticsCategories: { [name: string]:  StatisticsCategory[] } = {};
  private subs: Subscription[] = [];

  configurationsConfig: ConfigurationsConfig;

  constructor(private appStateService: AppStateService,
    private backendApiService: BackendApiService,
    private modalService: NgbModal,
  ) {
    const id = 1;
    this.gate = new GateControl({
      id: id,
      isConnected: false,
      gateType: GateType.GalaxyGate,
      mode: 'Normal',
      alarmState: [],
      anyFailedDevice: false,
      isLightAlarmActive: false,
    });
    this.status = new GateStatus({
      id: id,
      isConnected: false,
      mode: 'Normal',
      alarms: [],
      dispatcher: new Dispatcher(),
    });
    this.panel = new GateControlPanel({
      id: id,
      isConnected: false,
      isEmergency: false,
      isSuppressAlarm: false,
    });
  }

  ngOnInit() {
    this.updateDate();
    this.timer = window.setInterval(() => this.updateDate(), 1000);
    this.subs.push(this.appStateService.appState.stateChanged.subscribe(x => this.onStateChanged(x)));
    this.subs.push(this.appStateService.appState.appEventReceived
      .pipe(filter((x: AppEvent) => x.flag === AppEventFlag.ConfigurationConfig))
      .subscribe((x: AppEvent) => this.updateConfigurationConfig(x.data)));
    this.subs.push(this.appStateService.appAvailabilityStateChanged
      .pipe(filter((x: AppAvailabilityState) => x === AppAvailabilityState.Ready))
    .subscribe(() => this.reloadConfigurationConfig()));
    this.reloadConfigurationConfig();
  }

  ngOnDestroy() {
    this.subs.forEach(x => x.unsubscribe());
    window.clearInterval(this.timer);
  }

  reloadConfigurationConfig() {
    this.backendApiService.backendApi.getConfigurationsConfig().subscribe(x => this.updateConfigurationConfig(x), e => console.error(e));
  }

  private updateConfigurationConfig(data: Dict) {
    this.configurationsConfig = ConfigurationsConfig.fromJSON(data);
    this.updateState();
  }


  onStateChanged(x: StateChangeFlags) {
    /* tslint:disable:no-bitwise */
    if (x & StateChangeFlags.SimpleState
      || x & StateChangeFlags.DevicesStatus
      || x & StateChangeFlags.Alarms
      || x & StateChangeFlags.Confguration
      || x & StateChangeFlags.GateMode) {
      this.updateState();
    } else if (x & StateChangeFlags.StaticticsVisits) {
      this.updateStatistics();
    }
  }

  private updateState() {
    const gate = this.gate;
    const appState = this.appStateService.appState;
    const devices = appState.devicesStatus.items;
    const anyFailedDevice = devices.some(x => x.failure && !x.isOptional);
    const alarms = appState.alarms.map(x => x.name);
    const isLightAlarmActive = appState.isLightAlarmActive;
    const mode = appState.gateModeManagerState.value;
    const isSuppressAlarm = _.get(appState, 'configuration.general.general.suppress_all_alarms', true);
    const isEmergency = mode === 'Emergency';
    const gateAttributes = appState.gate;
    const gateType = gateAttributes ? gateAttributes.gateType : GateType.GalaxyGate;
    console.log(this.configurationsConfig);
    gate.update({
      isConnected: true,
      mode: mode,
      alarmState: alarms,
      anyFailedDevice: anyFailedDevice,
      simpleState: appState.simpleState,
      gateType: gateType,
      isLightAlarmActive: isLightAlarmActive,
    });
    Object.assign(this.status, {
      isConnected: true,
      alarms: alarms,
      devices: devices,
      simpleState: appState.simpleState,
      mode: mode,
      isSuppressAlarm: isSuppressAlarm,
      configurationsConfig: this.configurationsConfig,
    });
    Object.assign(this.panel, {
      isConnected: true,
      isSuppressAlarm: isSuppressAlarm,
      isEmergency: isEmergency,
    });
  }

  suppressAlarm(value: boolean) {
    const api = this.backendApiService.backendApi;
    api.getModelConfiguration('general', 'general').subscribe(x => {
      const c = x.modelConfiguration[0];
      if (c.suppress_all_alarms !== value) {
        c.suppress_all_alarms = value;
        api.updateModelConfiguration('general', 'general', c).subscribe(() => { }, (e) => console.log(e));
      }
    },
      e => console.log(e));
  }

  restart() {
    this.backendApiService.backendApi
      .restartApplication()
      .subscribe(() => { }, (e) => console.log(e));
  }

  private updateDate() {
    const oldFrom = this.from;
    const newFrom = moment().startOf('day').toDate();
    if (oldFrom && oldFrom.getTime() === newFrom.getTime()) {
      return;
    }

    this.from = newFrom;
    this.updateStatistics();
  }

  private updateStatistics() {
    const dateSpan = this.getStatisticsDateSpan();
    this.backendApiService.backendApi
      .getStatisticsVisits(dateSpan[0], dateSpan[1])
      .subscribe(x => this.updateCounters(x),
        (e) => console.error(e));
  }

  private updateCounters(items: any[]) {
    const r = [0, 0];
    items.forEach(x => x.count > 0 ? ++r[0] : ++r[1]);
    this.entryCount = r[0], this.exitCount = r[1];
  }

  actionCallback(action: GateAction, gateId?: number) {
    const a = this.backendApiService.backendApi;
    const actions = new Map([
      [GateAction.Locked, a.setGateModeLocked.bind(a)],
      [GateAction.LockedEntry, a.setGateModeLockedEntry.bind(a)],
      [GateAction.LockedExit, a.setGateModeLockedExit.bind(a)],
      [GateAction.Normal, a.setGateModeNormal.bind(a)],
      [GateAction.ServiceEntry, a.setGateModeServiceEntry.bind(a)],
      [GateAction.ServiceExit, a.setGateModeServiceExit.bind(a)],
      [GateAction.SingleOpenEntry, a.gateSingleOpenEntry.bind(a)],
      [GateAction.SingleOpenExit, a.gateSingleOpenExit.bind(a)],
      [GateAction.Emergency, a.gateModeEmergency.bind(a)],
      [GateAction.EmergencyCancel, a.gateModeEmergencyCancel.bind(a)]
    ]);

    actions.get(action)().subscribe(() => { }, error => console.error(error));
  }

  paneActionCallback(action: GateAction, gateId?: number) {
    const a = this.backendApiService.backendApi;
    const actions = new Map([
      [GateAction.Emergency, a.gateModeEmergency.bind(a)],
      [GateAction.EmergencyCancel, a.gateModeEmergencyCancel.bind(a)],
      [GateAction.SuppressAlarm, () => this.suppressAlarm.bind(this)(true)],
      [GateAction.SuppressAlarmCancel, () => this.suppressAlarm.bind(this)(false)],
      [GateAction.RestartApp, () => this.restart.bind(this)(false)],
    ]);
    actions.get(action)()?.subscribe(() => { }, error => console.error(error));
  }

  get canResetStatistics(): boolean {
    return this.entryCount > 0 || this.exitCount > 0;
  }

  private getStatisticsDateSpan(): [Date, Date] {
    const from = moment(this.from);
    const to = from.clone().add(1, 'day');
    return [from.toDate(), to.toDate()];
  }

  resetStatistics() {
    const m = this.modalService.open(ModalComponent);
    m.componentInstance.title = 'Reset statistics';
    m.componentInstance.message = 'Do you really want to reset the statistics for today?';
    m.result.then(() => this._resetStatistics());
  }

  private _resetStatistics() {
    const dateSpan = this.getStatisticsDateSpan();
    this.backendApiService.backendApi
      .resetStatisticsVisits(dateSpan[0], dateSpan[1])
      .subscribe(x => this.updateStatistics(),
        (e) => console.error(e));
  }

  getStatisticsCategory(name: string): StatisticsCategory[] {
    let value = this.statisticsCategories[name];
    if (!value) {
      value = this.appStateService.appState.statistics.statisticsCategories.filter(x => x.name === name) || [];
      this.statisticsCategories[name] = value;
    }
    return value;
  }
  applyConfigurationCallback(x: IConfigurationInfo) {
    this.backendApiService.backendApi.applyConfigConfiguration(x.name).subscribe(() => { }, (e) => console.error(e));
  }
}
