import { Component, OnInit } from '@angular/core';
import { RolesService } from 'src/app/services/backend-api/roles.service';
import { AppState } from 'src/app/models/app-state';
import { AppStateService } from 'src/app/services/app-state.service';
import { HtmlColor } from 'src/app/modules/shared/models/html-color';

@Component({
  selector: 'app-fmcu-setup-page',
  templateUrl: './fmcu-setup-page.component.html',
  styleUrls: ['./fmcu-setup-page.component.css']
})
export class FmcuSetupPageComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
