import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter
} from '@angular/core';

class Thema {
  color: string;
  constructor(color: string) {
    this.color = color;
  }
}

const THEMES = {
  default: new Thema('#ddd'),
  warning: new Thema('#ca313f')
};

@Component({
  selector: 'app-switch',
  templateUrl: 'switch.component.html',
  styleUrls: ['switch.component.scss']
})
export class SwitchComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() reverse = false;
  @Input() size = 'medium'; // 'small', 'large'
  @Input() cssClass = 'default';
  @Output() change = new EventEmitter<boolean>();

  get thema(): Thema {
    const thema = THEMES[this.cssClass];
    return thema == null ? THEMES['default'] : thema;
  }

  onChange(checked: boolean) {
    this.change.emit(checked);
  }
}
