import { Injectable } from '@angular/core';
import { IStatisticsProvider } from 'fmcu-core-ng';
import { Observable } from 'rxjs/Observable';
import { BackendApiService } from './backend-api/backend-api.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService implements IStatisticsProvider {

  getStatistics(filter): Observable<any> {
    return this.backendApiService.backendApi.getStatisticsVisits(filter.from, filter.to);
  }

  constructor(
    private backendApiService: BackendApiService,
  ) { }
}
