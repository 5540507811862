export class RandomWinnerHistoryItem {
  id: number;
  dateCreated: Date;
  direction: string;
  count: number;
  barcode: string;
  dateReset: Date;
  resetSource: string;

  constructor(id: number,
    dateCreated: Date,
    direction: string,
    count: number,
    barcode: string,
    dateReset: Date,
    resetSource: string
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.direction = direction;
    this.count = count;
    this.barcode = barcode;
    this.dateReset = dateReset;
    this.resetSource = resetSource;
  }

  static fromJson(x: any): RandomWinnerHistoryItem {
    return new RandomWinnerHistoryItem(
      x.id,
      new Date(x.date_created),
      x.direction ? 'Entry' : 'Exit',
      x.count,
      x.barcode,
      x.date_reset ? new Date(x.date_reset) : null,
      x.reset_source
    );
  }
}
