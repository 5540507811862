import { Bridge } from './bridge';
import { BackendHelper } from '../backend-helper';
import { PythonConvertor } from '../python-convertor';
import { ThreeState, ThreeStateUtil } from '../three-state';

const USB_PORT_COUNT = 3;

export enum UsbHubLocationEnum {
  Local = 'Local',
  Remote = 'Remote',
}

export class UsbPort extends Bridge<ThreeState> {
  pythonDevice: string;
  portIndex: number;

  constructor(pythonDevice: string, portIndex: number) {
    super(ThreeState.Off);
    this.pythonDevice = pythonDevice;
    this.portIndex = portIndex;
  }

  get name(): string {
    return (this.portIndex + 1).toString();
  }

  get boolValue(): boolean {
    return this.value === ThreeState.On;
  }

  set boolValue(val: boolean) {
    if (this.value === ThreeState.Undefined)  {
      return;
    }

    this.value = val ? ThreeState.On : ThreeState.Off;
  }

  get enabled() {
    return this.value !== ThreeState.Undefined;
  }

  get exec(): string {
    const method = BackendHelper.getPathDeviceMethod(
      this.pythonDevice,
      'set_switch'
    );
    return `${method}(${this.portIndex + 1}, ${this.value})`;
  }

  updateValueFromJson(json) {
    this._value = ThreeStateUtil.fromString(json.value);
  }
}

export class UsbHub {
  private _usbPorts = [];
  private location: UsbHubLocationEnum;

  constructor(location: UsbHubLocationEnum) {
    this.location = location;
    for (let i = 0; i < USB_PORT_COUNT; i++) {
      this._usbPorts.push(new UsbPort(this.pythonDevice, i));
    }
  }

  static getUsbLocations(): UsbHubLocationEnum[] {
    return Object.keys(UsbHubLocationEnum).map(x => UsbHubLocationEnum[x]);
  }

  get name(): string {
    return `${this.location}`;
  }

  get usbPorts(): UsbPort[] {
    return this._usbPorts;
  }

  getUsbPort(x: number): UsbPort {
    return this._usbPorts[x];
  }

  get pythonDevice(): string {
    return `usb_hub_${this.location.toString().toLowerCase()}`;
  }
}
