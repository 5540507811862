import { Component, Input } from '@angular/core';
import { DispatcherStatus, DispatchersStatus } from '../../../models/status/dispatcher-status';
import { HtmlColor } from '../../../modules/shared/models/html-color';

@Component({
      selector: 'app-status-dispatcher',
    templateUrl: 'status-dispatcher.component.html',
    styleUrls: ['status-dispatcher.component.scss']
})
export class StatusDispatcherComponent {
  @Input() dispatchersStatus: DispatchersStatus;


  connected(x: DispatcherStatus): string {
    return x.value ? 'Connected' : 'Disconnected';
  }

  get HtmlColor(): any {
    return HtmlColor;
  }
}
