import { Component, ViewEncapsulation, Input } from '@angular/core';
import { DevicesStatus } from '../../../models/status/devices-status';

@Component({
      selector: 'app-status-devices',
    templateUrl: 'status-devices.component.html',
    styleUrls: ['status-devices.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class StatusDevicesComponent {
  @Input() devicesStatus: DevicesStatus;
}
