import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GateSignal } from 'src/app/models/gate-signal/gate-signal';

@Component({
  selector: 'app-gate-signals-list-popup',
  templateUrl: './gate-signals-list-popup.component.html',
  styleUrls: ['./gate-signals-list-popup.component.css']
})
export class GateSignalsListPopupComponent implements OnInit {

  modalHeader: string;
  gateSignals: GateSignal[];

  constructor(
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
  }

  getGateSignalsEntry() {
    if (!this.gateSignals) {
      return [];
    }

    const gateSignalsEntry = this.gateSignals.filter(x => x.gateSide === 'Entry' && x.enabled === true);
    return gateSignalsEntry;
  }

  getGateSignalsExit() {
    if (!this.gateSignals) {
      return [];
    }

    const gateSignalsExit = this.gateSignals.filter(x => x.gateSide === 'Exit' && x.enabled === true);
    return gateSignalsExit;
  }

  getGateSignalsNotSet() {
    if (!this.gateSignals) {
      return [];
    }

    const gateSignalsNotSet = this.gateSignals.
    filter(x => x.gateSide !== 'Entry' && x.gateSide !== 'Exit'  && x.enabled === true);
    return gateSignalsNotSet;
  }
}
