import { Bridge } from '../bridge/bridge';
import { PythonConvertor } from '../python-convertor';
import { ComboBoxItem } from 'fmcu-core-ng';

export class DispatcherStatus extends Bridge<boolean> {
  id: string;
  name: string;
  type: string;
  error: string;
  instanceName: string;
  value: boolean;
  isEnabled: boolean;
  isSimulator: boolean;
  testBarcodes: ComboBoxItem[] = [];
  lastUploadPhotoUrl: string;

  constructor(id: string = 'Not set') {
    super(false);
    this.id = id;
  }

  updateFromJson(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.type = json.type;
    this.error = json.error;
    this.updateValueFromJson(json.is_connected);
    this.isEnabled = json.is_enabled;
    this.isSimulator = json.is_simulator;
    if (json.test_barcodes) {
      this.testBarcodes = json.test_barcodes.map(x => new ComboBoxItem(x.value, x.info));
    }
    this.lastUploadPhotoUrl = json.last_upload_photo_url;
  }

  get exec(): string {
    return `root.dispatcher_manager.simulate_no_connection('${this.id}', ${PythonConvertor.toBoolean(!this.value)})`;
  }
}

export class DispatchersStatus {
  _map = new Map<string, DispatcherStatus>();

  getItem(id: string): DispatcherStatus {
    return this._map.get(id);
  }

  get items(): DispatcherStatus[] {
    return Array.from(this._map.values());
  }

  get is_dispatchers_exist() {
    return this.items.length > 0;
  }

  updateItemFromJson(json: any) {
    const id = json.id;
    const item = this.getItem(id);
    if (item != null) {
      item.updateFromJson(json);
    }
  }

  updateFromJson(json: any) {
    json.forEach(x => {
      const dispatcher = x.dispatcher;
      const id = dispatcher.id;
      let item = this.getItem(id);
      if (item == null) {
        item = new DispatcherStatus(id);
        this._map.set(id, item);
      }
      item.updateFromJson(dispatcher);
    });
  }
}
