export enum UnitSelection {
  None = 0,
  // tslint:disable-next-line:no-bitwise
  Unit1 = 1 << 0,
  // tslint:disable-next-line:no-bitwise
  Unit2 = 1 << 1,
  // tslint:disable-next-line:no-bitwise
  All = Unit1 | Unit2
}

export function getUnitSelectionString(unitSelection: UnitSelection) {
  switch (unitSelection) {
    case UnitSelection.None:
      return '';
    case UnitSelection.Unit1:
      return 'Unit1';
    case UnitSelection.Unit2:
      return 'Unit2';
    default:
      return 'Unit1 & Unit2';
  }
}
