import { Dict } from './interfaces';

/* tslint:disable:no-bitwise */
export enum AppEventFlag {
  ConfigurationConfig = 1 << 0,
}

export class AppEvent {
  flag: AppEventFlag;
  data: Dict;

  constructor(flag: AppEventFlag, data: Dict) {
    this.flag = flag;
    this.data = data;

  }
}
