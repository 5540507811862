import { GateSignal, GateSignalJson } from './gate-signal';
import { Subject } from 'rxjs/Subject';

export class GateSignalsManager {
  protected map = new Map<number, GateSignal>();
  collectionChanged = new Subject();


  get items(): GateSignal[] {
    return Array.from(this.map.values());
  }

  getItemByObjectId(objectId: string): GateSignal {
    let item = null;
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].objectId === objectId) {
        item = this.items[i];
        break;
      }
    }
    return item;
  }

  getIndexByGateSignal(gateSignal: GateSignal): number {
    if (!gateSignal) {
      return -1;
    }

    const index = this.items.indexOf(gateSignal);

    return index;
  }

  loadFromJson(jsonItems: GateSignalJson[]) {
    this.map.clear();
    let i = 0;
    jsonItems.forEach(x => {
      const item = GateSignal.fromJson(x);
      this.map.set(i, item);
      i++;
    });
    this.collectionChanged.next();
  }

  updateFromJson(json: GateSignalJson) {
    const item = this.getItemByObjectId(json.object_id);
    if (item) {
      item.updateFromJson(json);
      this.collectionChanged.next();
    }
  }
}
