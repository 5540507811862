import 'rxjs/add/operator/filter';
import { Injectable } from '@angular/core';
import { AppState } from '../models/app-state';
import { BackendApiService } from './backend-api/backend-api.service';
import { BackendEventsService } from './backend-events/backend-events.service';
import { IBackendApi } from './backend-api/backend-api.interface';
import { IBackendEvents } from './backend-events/backend-events-interface';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { AuthService } from './auth.service';
import { LightBarController } from '../models/light_bar/light_bar_controller';

export enum AppAvailabilityState {
  None,
  Connecting,
  Initializing,
  NotAuthenticated,
  LoadingData,
  Ready,
}

@Injectable()
export class AppStateService {
  private timer: any = null;
  private _appState: AppState;
  private backendApi: IBackendApi;
  private backendEvents: IBackendEvents;
  appStateReloaded = new Subject<any>();
  private isInitialized = false;
  private subscribers: Subscription[] = [];
  private _appAppAvailabilityState = AppAvailabilityState.None;
  appAvailabilityStateChanged = new Subject<AppAvailabilityState>();


  constructor(
    private authService: AuthService,
    private backendApiService: BackendApiService,
    private backendEventsService: BackendEventsService
  ) {
    this.backendApi = backendApiService.backendApi;
    this._appState = new AppState(this.backendApi);
    this.backendEvents = backendEventsService.backendEvents;
    backendEventsService.backendEvents.messages.subscribe(x =>
      this.onMessage(x)
    );

    authService.appStateService = this;
    authService.authenticationChanged.subscribe(x => {
      if (x) {
        this.ensureBackendInitialized();
      } else {
        this.isInitialized = false;
      }
    });

    this.backendEvents.connectionChanged.subscribe(
      y => {
        if (y) {
          this.ensureBackendInitialized();
        } else {
          this.isInitialized = false;
        }
        this.updateConnectionState();
      }
    );
    this.updateConnectionState();
  }

  private updateConnectionState() {
    if (!this.backendEvents.isConnected) {
      this.appAppAvailabiltyState = AppAvailabilityState.Connecting;
    }
  }

  get appAppAvailabiltyState(): AppAvailabilityState {
    return this._appAppAvailabilityState;
  }

  set appAppAvailabiltyState(x: AppAvailabilityState) {
    if (x === this._appAppAvailabilityState) {
      return;
    }

    this._appAppAvailabilityState = x;
    this.appAvailabilityStateChanged.next(x);
  }

  get appState(): AppState {
    return this._appState;
  }

  updateAppState() {
    this.backendApi.getAppState().subscribe(x => {
      this._appState.updateFromJson(x);
      this.onAppStateReloaded();
      this.appStateReloaded.next();
      this.appAppAvailabiltyState = AppAvailabilityState.Ready;
    });
  }

  private ensureBackendInitialized() {
    if (!this.authService.isAuthenticated) {
      this.appAppAvailabiltyState = AppAvailabilityState.NotAuthenticated;
      this.postponeUpdateAppState();
      return;
    }

    if (this.isInitialized) {
      return;
    }

    this.appAppAvailabiltyState = AppAvailabilityState.Initializing;

    this.backendApi
      .isInitialized()
      .subscribe(
        x => {
          if (x && x.isInitialized) {
            this.appAppAvailabiltyState = AppAvailabilityState.LoadingData;
            this.updateAppState();
            this.isInitialized = true;
          } else {
            this.postponeUpdateAppState();
          }
        },
        error => this.postponeUpdateAppState()
      );
  }

  private postponeUpdateAppState() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.ensureBackendInitialized(), 2000);
  }

  private onMessage(json: any) {
    if (this.isInitialized) {
      this._appState.updateFromJson(json);
    }
  }

  private onAppStateReloaded() {
    const appState = this.appState;
    const backendApi = this.backendApiService.backendApi;
    this.subscribers.forEach(x => x.unsubscribe());
    appState.bridgedModels.forEach(x =>
      this.subscribers.push(
        x.backendStatementCreated.subscribe(y => {
          const a = 0;
          backendApi.exec(y);
        })
      )
    );
  }
}
