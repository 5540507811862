import { IResource } from '../role-resource/resource';


export class Role {
  objectId: string;
  name: string = undefined;
  resources: IResource[] = [];
  firstSetupSteps: IResource[] = [];
  isFirstSetupStepsFinished = false;

  constructor() { }

  static arrayFromJSON(json: string): Role[] {
    const values = JSON.parse(json);
    if (!values || !Array.isArray(values)) {
      return null;
    }

    const result: Role[] = [];
    for (const item of values) {
      result.push(Role.createFromAny(item));
    }

    return result;
  }

  static createFromAny(object: any): Role {
    if (object === null || object === undefined) {
      return null;
    }
    const role = new Role();
    role.objectId = object.object_id;
    role.name = object.name;
    role.resources = object.resources ? object.resources.map(x => IResource.createFromAny(x)) : null;
    role.firstSetupSteps = object.first_setup_steps ? object.first_setup_steps.map(x => IResource.createFromAny(x)) : null;
    role.isFirstSetupStepsFinished = object.is_first_setup_steps_finished;
    return role;
  }

  toJSON(): any {
    return {
      name: this.name,
      resources: this.resources,
      firstSetupSteps: this.firstSetupSteps,
      isFirstSetupStepsFinished: this.isFirstSetupStepsFinished,
    };
  }

}
