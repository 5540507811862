import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AppState } from '../../models/app-state';
import { AppStateService } from '../../services/app-state.service';

@Component({
  selector: 'app-status-page',
  templateUrl: 'status-page.component.html',
  styleUrls: ['status-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusPageComponent {
  appState: AppState;

  constructor(private appStateService: AppStateService) {
    this.appState = appStateService.appState;
  }
}
