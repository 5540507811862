import * as $ from 'jquery';

export class Animator {
  private animations = {};
  private doorAnimation = '';

  updateDoorAnimation(doorAnimation: string) {
    this.doorAnimation = doorAnimation;
    const parts = ['slave', 'master'];
    for (const part of parts) {
      const $el = $(`#gate_door_${part}`);
      const tag = 'gate_door_ani';
      this.removeAnimation($el, tag);
      const name = `${doorAnimation}_${part}`;
      this.doAnimation($el, name, () => {
        return this.createBarAnimation(name, tag);
      });
    }
  }

  removeAnimation($el, tag) {
    $el.find(`[tag='${tag}']`).remove();
  }

  doAnimation($el, key, fabric) {
    const ani = this.getAnimation(key, fabric);
    if (ani) {
      $el.append(ani);
      ani.beginElement();
    }
  }

  getAnimation(key, fabric) {
    let ani = this.animations[key];

    if (ani) {
      return ani;
    }

    ani = fabric();
    this.animations[key] = ani;
    return ani;
  }

  createBarAnimation(ani_name: string, tag: string): any {
    const ani = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'animateTransform'
    );
    ani.setAttribute('attributeName', 'transform');
    ani.setAttribute('attributeType', 'xml');
    ani.setAttribute('type', 'rotate');
    ani.setAttribute('dur', '500ms');
    ani.setAttribute('repeatCount', '0');
    ani.setAttribute('fill', 'freeze');
    ani.setAttribute('begin', 'indefinite');
    ani.setAttribute('tag', tag);
    if (ani_name === 'gate_door_entry_open_master') {
      ani.setAttribute('from', '0 242 221');
      ani.setAttribute('to', '90 240 225');
    } else if (ani_name === 'gate_door_entry_close_master') {
      ani.setAttribute('from', '90 240 225');
      ani.setAttribute('to', '0 242 221');
    } else if (ani_name === 'gate_door_exit_open_master') {
      ani.setAttribute('from', '0 242 221');
      ani.setAttribute('to', '-90 236 225');
    } else if (ani_name === 'gate_door_exit_close_master') {
      ani.setAttribute('from', '-90 236 225');
      ani.setAttribute('to', '0 242 221');
    } else if (ani_name === 'gate_door_entry_open_slave') {
      ani.setAttribute('from', '0 60 221');
      ani.setAttribute('to', '-90 60 225');
    } else if (ani_name === 'gate_door_entry_close_slave') {
      ani.setAttribute('from', '-90 60 225');
      ani.setAttribute('to', '0 60 221');
    } else if (ani_name === 'gate_door_exit_open_slave') {
      ani.setAttribute('from', '0 60 221');
      ani.setAttribute('to', '90 64 225');
    } else if (ani_name === 'gate_door_exit_close_slave') {
      ani.setAttribute('from', '90 64 225');
      ani.setAttribute('to', '0 60 221');
    } else {
      return null;
    }
    return ani;
  }

  createLightAnimation(color, tag): any {
    const ani = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'animate'
    );
    ani.setAttribute('attributeName', 'fill');
    ani.setAttribute('attributeType', 'xml');
    ani.setAttribute('values', color + ';' + color + ';' + '#eee;');
    ani.setAttribute('dur', '1.0s');
    ani.setAttribute('repeatCount', 'indefinite');
    ani.setAttribute('tag', tag);
    return ani;
  }
}
