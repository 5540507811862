import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { AuthService, LOGIN_COMMAND } from './auth.service';
import { RolesService } from './backend-api/roles.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router,
    private authService: AuthService,
    private rolesService: RolesService,
  ) { }

  url = null;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    const url = state.url;
    if (!(['/login', '/logout'].find(x => url && url.startsWith(x)))) {
      this.rolesService.url = url;
    }

    if (!this.authService.isAuthenticated) {
      this.router.navigate([LOGIN_COMMAND]);
      return false;
    }

    if (!this.rolesService.canAccessPage(state.url)) {
      this.router.navigate(['unauthorised'], { state: { url: state.url } });
      return false;
    }

    return true;
  }
}
