import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Hint } from '../models/hint';
import { Operation } from '../models/operation';
import { ConfigurationInfo } from '../models/configuration-info';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { Observable, of, EMPTY } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable()
export class ConfigurationService {
  configurationReset = new Subject();
  hintRestartRequired = new Hint('Application\'s restart required', true);
  operation = new Operation();
  currentConfigurationInfo: ConfigurationInfo;
  modelKey: string = null;
  templateConfigurationKey: string;

  private backendApi: IBackendApi;

  constructor(
    private backendApiService: BackendApiService,
  ) {
    this.backendApi = backendApiService.backendApi;
  }

  emitConfigurationReset() {
    this.configurationReset.next();
  }

  resetHintRestartRequired() {
    if (this.hintRestartRequired && !this.hintRestartRequired.clean) {
      this.hintRestartRequired.messages = [];
    }
  }

  reset() {
    this.currentConfigurationInfo = null;
    this.modelKey = null;
    this.templateConfigurationKey = null;
  }

  get isRestartRequired(): boolean {
    if (this.currentConfigurationInfo && this.currentConfigurationInfo.isRestartRequired) {
      return true;
    }

    return false;
  }

  get isDirty(): boolean {
    if (this.currentConfigurationInfo && this.currentConfigurationInfo.dirtyMessages
      && this.currentConfigurationInfo.dirtyMessages.length > 0) {
      return true;
    }

    return false;
  }

  get isInvalid(): boolean {
    if (this.currentConfigurationInfo && this.currentConfigurationInfo.invalidMessages
      && this.currentConfigurationInfo.invalidMessages.length > 0) {
      return true;
    }

    return false;
  }

  saveCurrentConfiguration(): Observable<boolean> {
    return of(true).pipe(
      switchMap(_ => {
        if (!this.currentConfigurationInfo || !this.currentConfigurationInfo.configuration || !this.modelKey) {
          return EMPTY;
        }

        const newConfiguration = this.currentConfigurationInfo.configuration;
        const objectId = this.currentConfigurationInfo.configuration.object_id;

        const editedFields = this.currentConfigurationInfo.editedFields;

        if (this.currentConfigurationInfo.configuration.object_id) {
          this.operation.start('Update');
          return this.backendApi.updateModelConfiguration(this.modelKey, objectId, newConfiguration, this.templateConfigurationKey, editedFields).pipe(map(__ => true));
        } else {
          this.operation.start('Save');
          return this.backendApi.saveModelConfiguration(this.modelKey, newConfiguration, this.templateConfigurationKey, editedFields).pipe(map(__ => false));
        }
      })
    );

  }
}
