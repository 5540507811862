import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalizedDatePipe } from './localized-date-pipe';

@NgModule({
 imports:      [ CommonModule ],
 declarations: [ LocalizedDatePipe ],
 exports:      [ CommonModule, LocalizedDatePipe ]
})
export class LocalizedDatePipeModule { }
