export class LastEvent {
  constructor(
    public dateTime: Date,
    public type: string,
    public info: string,
  ) { }

  static fromJson(json: any): LastEvent {
    return new LastEvent(
      new Date(json.dateTime),
      json.type,
      json.info,
    );
  }

  equals(other: LastEvent) {
    return this.dateTime.getTime() === other.dateTime.getTime()
      && this.type === other.type
      && this.info === other.info;
  }
}

export class LastEvents {
  events = new Array<LastEvent>();

  updateFromJson(json: { max_length: number, events: [] }) {
    const max_length = json.max_length;
    const new_events = json.events.splice(0, max_length).map((x) => LastEvent.fromJson(x));

    if (new_events.length === 0) {
      this.events = [];
    }

    const diff = this.events.length + new_events.length - max_length;

    if (diff > 0) {
      this.events.splice(-diff, diff);
    }

    new_events.reverse()
      .filter((x) =>
        this.events.findIndex((y) => y.equals(x)) === -1
      )
      .map((x) => {
        this.events.unshift(x);
      });

    this.events.sort((a, b) => {
      return this._getTime(b) - this._getTime(a);
    });
  }

  private _getTime(item: LastEvent) {
    return item != null && item.dateTime != null ? item.dateTime.getTime() : 0;
  }
}
