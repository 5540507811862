import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject
export class RandomWinnerTracker {
  @JsonProperty('direction', String)
  direction: string = undefined;
  @JsonProperty('enabled', Boolean)
  enabled: boolean = undefined;
  @JsonProperty('count', Number)
  count: number = undefined;
  @JsonProperty('winner_barcode', String)
  winnerBarcode: string = undefined;
}
