import { v4 as uuid } from 'uuid';
import { FileIoTaskStepState } from './file-io-task-step-state';
import { DateTime } from 'fmcu-core-ng';
import { FileIoDirection } from './file-io-direction';

export class FileIoTaskStep {
  name: string;
  fileName: string;
  remoteFilePath: string;
  info: string;
  id: string;
  dateStarted: Date;
  dateFinished: Date;
  progress: number;
  fileIoDirection: FileIoDirection;
  state: FileIoTaskStepState;
  commandData: any;

  constructor(
    name?: string,
    fileName?: string,
    remoteFilePath?: string,
    info?: string,
    id?: string,
    dateStarted?: Date,
    dateFinished?: Date,
    progress: number = 0,
    fileIoDirection: FileIoDirection = null,
    state = FileIoTaskStepState.Created,
    commandData = null,
  ) {
    this.id = id == null ? uuid() : id;
    this.name = name;
    this.dateStarted = dateStarted;
    this.dateFinished = dateFinished;
    this.progress = progress;
    this.info = info;
    this.state = state;
    this.fileName = fileName;
    this.remoteFilePath = remoteFilePath;
    this.fileIoDirection = fileIoDirection;
    this.commandData = commandData;
  }

  static getBaseNameFromFileName(fileName: string): string {
    return  fileName.replace(/^.*[\\\/]/, '');
  }

  static fromJson(json: any): FileIoTaskStep {
    const dateStarted =
      json.date_started == null ? null : new Date(json.date_started);
    const dateFinished =
      json.date_finished == null ? null : new Date(json.date_finished);
    return new FileIoTaskStep(
      json.name,
      json.fileName,
      json.remote_file_path,
      json.info,
      json.id,
      dateStarted,
      dateFinished,
      json.progress,
      json.file_io_direction,
      json.state,
      json.command_data
    );
  }

  updateFromOther(other: FileIoTaskStep) {
    this.name = other.name;
    this.info = other.info;
    this.dateStarted = other.dateStarted;
    this.dateFinished = other.dateFinished;
    this.progress = other.progress;
    this.state = other.state;
    this.fileName = other.fileName;
    this.remoteFilePath = other.remoteFilePath;
    this.fileIoDirection = other.fileIoDirection;
    this.commandData = other.commandData;
  }

  get duration(): string {
    return this.state === FileIoTaskStepState.Created ? '' : DateTime.getDurationString(this.dateStarted, this.dateFinished);
  }

  get progressStr(): string {
    return this.state === FileIoTaskStepState.Created ? '' : this.progress.toString() + '%';
  }

  setProgress(progress: number, success?: boolean, info?: string) {
    this.progress = progress;
    console.log(progress);
    if (info != null) {
      this.info = info;
    }
  }

  toString() {
    let s = `${this.id} ${this.name}`;
    s += ` dateStarted: ${this.dateStarted}`;
    s += ` dateFinished: ${this.dateFinished}`;
    s += ` progress: ${this.progress}`;
    s += ` state: ${this.state}`;
    s += ` fileName: ${this.fileName}`;
    s += ` remoteFilePath: ${this.remoteFilePath}`;
    s += ` fileIoDirection: ${this.fileIoDirection}`;
    return s;
  }
}
