import { Component, ViewEncapsulation } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-camera-page',
  templateUrl: 'camera-page.component.html',
  styleUrls: ['camera-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CameraPageComponent {

  constructor(
    public appStateService: AppStateService,
  ) { }

  get lastUploadPhotoUrl(): string {
    return this.appStateService.appState.activeDispatcher.lastUploadPhotoUrl;
  }
}
