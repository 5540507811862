import { Injectable } from '@angular/core';
import { ILog } from './log.interface';

const CSS = {
  INFO: '',
  WARNING: 'color: red',
  DEBUG: 'color: #ba4a00',
  ERROR: 'color: white; background-color: black'
};

function pad(num: number, size: number): string {
  const s = '000000000' + num.toString();
  return s.substr(s.length - size);
}

@Injectable()
export class LogService implements ILog {
  info(message: string) {
    this.log(message, 'INFO');
  }

  warning(message: string) {
    this.log(message, 'WARNING');
  }

  debug(message: string) {
    this.log(message, 'DEBUG');
  }

  error(message: string) {
    this.log(message, 'ERROR');
  }

  private log(message: string, level: string) {
    const date = new Date();
    const day = pad(date.getDate(), 2);
    const monthIndex = pad(date.getMonth(), 2);
    const year = date.getFullYear();
    const hours = pad(date.getHours(), 2);
    const minutes = pad(date.getMinutes(), 2);
    const seconds = pad(date.getSeconds(), 2);
    const milliseconds = pad(date.getMilliseconds(), 3);
    console.log(
      `%c[${hours}:${minutes}:${seconds}.${milliseconds}] - ${message}`,
      CSS[level]
    );
  }
}
