import { Component } from '@angular/core';
import { BackendEventsService } from '../../../services/backend-events/backend-events.service';
import { AppStateService } from '../../../services/app-state.service';
import { DateTime } from 'fmcu-core-ng';
import { OnDestroy } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

@Component({
  selector: 'app-gate-info-system',
  templateUrl: 'gate-info-system.component.html',
  styleUrls: ['gate-info-system.component.scss']
})
export class GateInfoSystemComponent implements OnDestroy {
  timeDiff = 0;
  timeDiffStr = '';
  private timer = null;
  private testTime = addDays(GateInfoSystemComponent.now(), -1);

  constructor(private backendEventsService: BackendEventsService,
    private appStateService: AppStateService,
    private backendApiService: BackendApiService) {
    this.updateTimeDiff();
  }

  private static now(): Date {
    return new Date(Date.now());
  }

  ngOnDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  get deviceTime(): Date {
    // return this.testTime;
    return this.currentTime;
  }

  private updateTimeDiff() {
    const deviceTime = this.deviceTime;
    if (deviceTime) {
      const currentTime = GateInfoSystemComponent.now();
      this.timeDiff = Math.floor((currentTime.getTime() - deviceTime.getTime()) / 1000);
      this.timeDiffStr = DateTime.getDurationString(deviceTime, currentTime);
    }

    this.timer = setTimeout(() => this.updateTimeDiff(), 1000);
  }


  get currentTime(): any {
    return this.backendEventsService.backendEvents.fmcuDate;
  }

  get startTime(): any {
    return this.appStateService.appState.startTime;
  }

  get duration(): string {
    return DateTime.getDurationString(this.startTime, this.currentTime);
  }

  get showTimeDifference() {
    return Math.abs(this.timeDiff) > 60;
  }

  systemTime() {
    this.backendApiService.backendApi.setSystemTime(GateInfoSystemComponent.now())
    .subscribe(() => location.reload(), (e) => console.error(e));
  }
}
