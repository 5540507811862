import { Component, OnInit, OnDestroy } from '@angular/core';
import { RolesService } from 'src/app/services/backend-api/roles.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AppState } from 'src/app/models/app-state';
import { HtmlColor } from '../../models/html-color';
import { ConfigurationService } from '../../services/configuration.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FirstSetupStepsStateService } from 'src/app/services/first-setup-steps-state.service';

@Component({
  selector: 'app-first-setup-steps-navigation',
  templateUrl: './first-setup-steps-navigation.component.html',
  styleUrls: ['./first-setup-steps-navigation.component.css']
})
export class FirstSetupStepsNavigationComponent implements OnInit, OnDestroy {
  appState: AppState;
  isNeedRestart = false;
  saveCurrentConfigurationSubscription: Subscription;

  constructor(
    private rolesService: RolesService,
    private appStateService: AppStateService,
    private configurationService: ConfigurationService,
    private firstSetupStepsStateService: FirstSetupStepsStateService,
    private router: Router,
  ) {
    this.appState = appStateService.appState;
  }

  ngOnInit(): void {
    if (this.isSetupStepsActive) {
      this.rolesService.initializeCurrentSetupStep();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeSaveCurrentConfiguration();
  }

  get isSetupStepsActive(): boolean {
    return this.rolesService.isSetupStepsActive;
  }

  get isBackButtonHidden(): boolean {
    return this.rolesService.isFirstSetupStep;
  }

  get nextButtonCaption(): string {
    if (this.rolesService.isLastSetupStep) {
      return 'Finish';
    }

    return 'Next';
  }

  _nextSetupStepClick() {
    if (!this.rolesService.isLastSetupStep) {
      this.rolesService.setNextSetupStep();
    } else {
      this.rolesService.finishSetupSteps(this.isNeedRestart);
    }
  }

  nextSetupStepClick() {
    if (this.configurationService.currentConfigurationInfo && this.configurationService.isDirty) {
      this.unsubscribeSaveCurrentConfiguration();
      this.saveCurrentConfigurationSubscription = this.configurationService.saveCurrentConfiguration().subscribe(
        x => {
          this._nextSetupStepClick();
        }
      );
    } else {
      this._nextSetupStepClick();
    }
  }

  previousSetupStepClick() {
    if (!this.rolesService.isFirstSetupStep) {
      this.rolesService.setPreviousSetupStep();
    }
  }

  unsubscribeSaveCurrentConfiguration() {
    if (this.saveCurrentConfigurationSubscription) {
      this.saveCurrentConfigurationSubscription.unsubscribe();
      this.saveCurrentConfigurationSubscription = null;
    }
  }

  get currentDeviceFailure(): string {
    if (!this.isDevice) {
      return '';
    }

    const devices = this.appState.devicesStatus.items;
    for (const device of devices) {
      if (device.objectId === this.rolesService.currentSetupStep.modelObjectId && device.failure) {
        return device.failure;
      }
    }

    return '';
  }

  get isDevice(): boolean {
    if (!this.rolesService.currentSetupStep || !this.rolesService.currentSetupStep.modelObjectId
      || !this.appState.devicesStatus || !this.appState.devicesStatus.is_devices_exist) {
      return false;
    }

    const device = this.appState.devicesStatus.items.filter(x => x.objectId === this.rolesService.currentSetupStep.modelObjectId);
    if (device && device.length > 0) {
      return true;
    }

    return false;
  }

  getDeviceStatus(): string {
    if (!this.isDevice) {
      return '';
    }

    const deviceFailure = this.currentDeviceFailure;
    if (deviceFailure) {
      return deviceFailure;
    }

    return 'Ready';
  }

  get HtmlColor(): any {
    return HtmlColor;
  }

  get isLastSetupStep(): boolean {
    return this.rolesService.isLastSetupStep;
  }

  get currentStepName(): string {
    return this.rolesService.currentStepName;
  }

  get isNextButtonDisabled(): boolean {
    if (this.configurationService && this.configurationService.isInvalid) {
      return true;
    }

    if (this.firstSetupStepsStateService.isUpdateInProgress || this.firstSetupStepsStateService.isNetworkDirty) {
      return true;
    }

    return false;
  }

  get isBackButtonDisabled(): boolean {
    if (this.firstSetupStepsStateService.isUpdateInProgress) {
      return true;
    }

    return false;
  }

  get isFinishButtonVisible(): boolean {
    if (this.isLastSetupStep && this.router.url && this.rolesService.currentSetupStep 
      && (this.router.url === '/fmcu-configuration' 
          || (this.router.url === '/configuration' 
              && this.rolesService.currentSetupStep.resourceIds.indexOf('configuration-type') != -1 
              && this.firstSetupStepsStateService.currentConfigurationType 
              && this.firstSetupStepsStateService.installerGateConfigurationId
              && this.firstSetupStepsStateService.currentConfigurationType !== this.firstSetupStepsStateService.installerGateConfigurationId))) {
        return false;
    }

    return true;
  }

}
