import { Component, Input } from '@angular/core';
import { Hint } from '../../models/hint';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorRegex } from '../../models/translator-regex';

@Component({
  selector: 'app-hints',
  templateUrl: 'hints.component.html',
  styleUrls: ['hints.component.scss']
})
export class HintsComponent {
  @Input() hints: Hint[];
  private translatorRegex: TranslatorRegex;

  constructor(private translateService: TranslateService) {
    this.translatorRegex = new  TranslatorRegex(translateService);
  }

  getCssClass(x: Hint): string {
    let s = 'badge badge-secondary badge-pill mr-1 ';
    const disabled = x.messages == null || x.messages.length === 0;
    s += disabled ? 'badge-default' : this.getNotDefaultCssClass(x);
    return s;
  }

  getNotDefaultCssClass(x: Hint): string {
    return x.danger ? 'badge-danger' : 'badge-warning';
  }

  getTitle(hint: Hint): string {
    if (hint.messages == null) {
      return '';
    }

    let s = '';
    hint.messages.forEach(x => {
      s += this.translatorRegex.translate(x);
      s += '\n';
    });
    s.trim();
    return s;
  }
}
