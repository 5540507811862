import { Component, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { AppStateService, AppAvailabilityState } from 'src/app/services/app-state.service';
import { Subscription } from 'rxjs';
import { tickStep } from 'd3-array';

@Component({
  selector: 'app-no-connection',
  templateUrl: 'no-connection.component.html',
  styleUrls: ['no-connection.component.scss']
})
export class NoConnectionComponent implements OnDestroy {
  private _message: string;
  subscription: Subscription;

  constructor(private appService: AppStateService) {

    this.subscription = appService.appAvailabilityStateChanged.subscribe((x: AppAvailabilityState) => this.onAppAvailabiltyStateChanged(x));
    this.updateState();

  }

  private onAppAvailabiltyStateChanged(x: AppAvailabilityState) {
    this.updateState();
  }

  private updateState() {
    const state = this.appService.appAppAvailabiltyState;
    switch (state) {
      case AppAvailabilityState.None:
      case AppAvailabilityState.Connecting:
        this.message = 'Connecting';
        break;
      case AppAvailabilityState.Initializing:
        this.message = 'Initializing';
        break;
      case AppAvailabilityState.LoadingData:
        this.message = 'Loading data';
        break;
       default:
        this.message = null;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get message(): string {
    return this._message;
  }

  set message(x : string) {
    this._message = x;
  }
}
