import { StatisticsItem } from '../../../models/statistics';
import { Highlighter } from 'fmcu-core-ng';

export class CssStyleProvider {
  private dummy: string;

  getCssClassAlignRight(highlighter: Highlighter): string {
    return `${highlighter.cssClassHighlight} align-right`;
  }

  getCssClassChild(highlighter: Highlighter): string {
    return `${highlighter.cssClassHighlight} child`;
  }
}
