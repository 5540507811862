import { Subject } from 'rxjs/Subject';
import { Highlighter } from 'fmcu-core-ng';

export class StatisticsItem extends Highlighter {
  id: string;
  name: string;
  value: any;
  dateTime: Date;

  constructor(id: string, name: string) {
    super();
    this.id = id;
    this.name = name;
  }

  updateFromJson(json: any) {
    this.dateTime = new Date(json.dateTime);
    this.value = json.value;
    this.highlight();
  }
}

export enum StatisticsKey {
  StatsEntryLastCardRead = 'stats_entry_last_card_read',
  StatsEntryLastCard_validated = 'stats_entry_last_card_validated',
  StatsEntryPersonCount = 'stats_entry_person_count',
  StatsEntryOpenCount = 'stats_entry_open_count',
  StatsEntryQueueSize = 'stats_entry_queue_size',

  StatsExitLastCardRead = 'stats_exit_last_card_read',
  StatsExitLastCardValidated = 'stats_exit_last_card_validated',
  StatsExitPersonCount = 'stats_exit_person_count',
  StatsExitOpenCount = 'stats_exit_open_count',
  StatsExitQueueSize = 'stats_exit_queue_size',

  StatsLastOpened = 'stats_last_opened',
  StatsLastClosed = 'stats_last_closed',
  StatsClosedOnTimeout = 'stats_closed_on_timeout',
  StatsFireAlarmCount = 'stats_fire_alarm_count',
  StatsEmergencyCount = 'stats_emergency_count'
}

export class StatisticsCategory {
  name: string;
  cssClass: string;
  private mapStatisticsItems = new Map<StatisticsKey, StatisticsItem>();

  constructor(name: string, cssClass: string) {
    this.name = name;
    this.cssClass = cssClass;
  }

  get statisticsItems(): StatisticsItem[] {
    return Array.from(this.mapStatisticsItems.values());
  }

  getStatisticsItem(id: StatisticsKey): StatisticsItem {
    return this.mapStatisticsItems.get(id);
  }

  updateFromJson(json: any) {
    json.forEach(x => {
      let item = this.mapStatisticsItems.get(x.id);
      if (!item) {
        item = new StatisticsItem(x.id, x.name);
        this.mapStatisticsItems.set(x.id, item);
      }
      item.value = x.value;
      item.dateTime = new Date(x.dateTime);
    });
  }
}

export class Statistics {
  private _statisticsCategories: StatisticsCategory[] = [
    new StatisticsCategory('Exit', 'fa fa-arrow-circle-down fa-lg fa-custom'),
    new StatisticsCategory('Entry', 'fa fa-arrow-circle-up fa-lg fa-custom'),
    new StatisticsCategory('Other', 'fa fa-cog fa-lg fa-custom')
  ];

  get statisticsCategories(): StatisticsCategory[] {
    return this._statisticsCategories;
  }

  getStatisticsCategory(name: string) {
    const cat = this.statisticsCategories.filter(x => x.name === name);
    return cat.length ? cat[0] : null;
  }

  getStatisticsItem(id: StatisticsKey): StatisticsItem {
    for (const c of this.statisticsCategories) {
      const item = c.getStatisticsItem(id);
      if (item) {
        return item;
      }
    }
    return null;
  }

  get queueEntrySize(): number {
    const item = this.getStatisticsItem(StatisticsKey.StatsEntryQueueSize);
    return item == null ? null : item.value;
  }

  get queueExitSize(): number {
    const item = this.getStatisticsItem(StatisticsKey.StatsExitQueueSize);
    return item == null ? null : item.value;
  }

  updateFromJson(json: any) {
    json.categories.forEach(c => {
      const cat = this.getStatisticsCategory(c.name);
      if (cat) {
        cat.updateFromJson(c.statistics_items);
      }
    });
  }

  updateStatisticsItemFromJson(json: any) {
    const item = this.getStatisticsItem(json.id);
    if (item) {
      item.updateFromJson(json);
    }
  }
}
