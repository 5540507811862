import { Injectable, Injector } from '@angular/core';
import { IBackendEvents } from './backend-events-interface';
import { environment } from '../../../environments/environment';
import { BackendEventsWebsocketService } from './backend-events-websocket';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class BackendEventsService {
  private _backendEvents: IBackendEvents;
  constructor(protected injector: Injector) {
    const isWebsocket =
      environment.unconditionallyUseWebSocket || environment.production;
    const service = isWebsocket
      ? BackendEventsWebsocketService
      : BackendEventsWebsocketService; // Replace with a simulator when it is needed
    this._backendEvents = injector.get(service);
  }

  get backendEvents(): IBackendEvents {
    return this._backendEvents;
  }
}
