import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const TIME_MIN = new Date('0001-01-01T00:00:00').getTime();

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, pattern: string = 'medium'): any {
    if (value instanceof Date && value.getTime() <= TIME_MIN) {
      return '';
    }
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    if (pattern === 'mediumDateTime') {
      return `${datePipe.transform(value, 'mediumDate')} ${datePipe.transform(
        value,
        'mediumTime'
      )}`;
    } else if (pattern === 'mediumDate') {
      return datePipe.transform(value, 'mediumDate');
    } else if (pattern === 'monthYear') {
      return `${datePipe.transform(value, 'LLLL')}, ${datePipe.transform(
        value,
        'yyyy'
      )}`;
    }

    return datePipe.transform(value, pattern);
  }
}
