export class Audio {
  pathToFile: string;
  fileName: string;
  volume: number;

  constructor(pathToFile: string, fileName: string, volume: number) {
    this.pathToFile = pathToFile;
    this.fileName = fileName;
    this.volume = volume;
  }
}
