import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-unauthorised-page',
    templateUrl: 'unauthorised-page.component.html',
    styleUrls: ['unauthorised-page.component.scss']
})
export class UnauthorisedPageComponent {
  url: string;

  constructor(private router: Router) {
    const state = this.router.getCurrentNavigation().extras.state;
    this.url = state ? state.url : null;
  }
}
