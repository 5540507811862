import { Component, Input } from '@angular/core';
import { ServerStatus } from '../../../models/status/server-status';
import { HtmlColor } from '../../../modules/shared/models/html-color';
import { BackendApiService } from '../../../services/backend-api/backend-api.service';
import { IBackendApi } from '../../../services/backend-api/backend-api.interface';

@Component({
  selector: 'app-status-server',
  templateUrl: 'status-server.component.html',
  styleUrls: ['status-server.component.scss']
})
export class StatusServerComponent {
  @Input() serverStatus: ServerStatus;
  private backendApi: IBackendApi;

  constructor(backendApiService: BackendApiService) {
    this.backendApi = backendApiService.backendApi;
  }

  get HtmlColor(): any {
    return HtmlColor;
  }

  connectionErrorClick() {
    this.backendApi
      .simulateConnectionServerError()
      .subscribe(x => x, e => console.error(e));
  }
}
