export class Factory {
  static fromJson<T>(type: new () => T, json: any, source?: T): T {
    if (source != null && Factory.equal(source, json)) {
      return source;
    }
    return Object.assign(new type(), json);
  }

  static equal(a, b): boolean {
    if (a == null || b == null) {
      return false;
    }

    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    if (aProps.length !== bProps.length) {
      return false;
    }

    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  updateFromJson(json: any) {
    Object.keys(json).forEach(key => {
      this[key] = json[key];
    });
  }
}
