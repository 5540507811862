import { GateSide } from '../gate-side';
import { BackendHelper } from '../backend-helper';
import { Bridge } from './bridge';

export enum BarcodeReaderStateEnum {
  Off,
  Idle,
  Reading
}

export class BarcodeReader extends Bridge<string> {
  readerIndex: number = null;
  name: string;
  state: BarcodeReaderStateEnum;

  constructor(readerIndex: number, name: string) {
    super('');
    this.readerIndex = readerIndex;
    this.name = name;
  }

  readValid() {
    this.backendStatementCreated.next(`${this.getPathMethod('simulate_read_internal_valid')}()`);
  }

  read(barcode: string) {
    this.backendStatementCreated.next(`${this.getPathMethod('simulate_read')}(barcode='${barcode}')`);
  }

  private getPathMethod(methodName: string): string {
    return BackendHelper.getPathReaderMethod(this.readerIndex, methodName);
  }
}
