export class UpdateSoftwareStatus {
    statusText: string;
    percentProgress: number;
    updateInProgress: boolean;
    errorText: string;

    updateFromJson(json: any) {
        this.statusText = json.status_text;
        this.percentProgress = json.percent_progress;
        this.updateInProgress = json.update_in_progress;
        this.errorText = json.error_text;
    }
}
