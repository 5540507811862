import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppStateService, AppAvailabilityState } from '../../services/app-state.service';
import { BackendEventsService } from '../../services/backend-events/backend-events.service';
import { Subscription } from 'rxjs/Subscription';
import { CookieService } from 'ngx-cookie';

const COOKIE_KEY = 'username';

@Component({
  selector: 'app-login-page',
  templateUrl: 'login-page.component.html',
  styleUrls: ['login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked {
  username = '';
  password = '';
  isLoginInProgressWithDelay = false;
  private isLoginInProgressSubscription: Subscription;

  constructor(
    private cdref: ChangeDetectorRef,
    private authService: AuthService,
    private cookieService: CookieService,
    private appStateService: AppStateService,
    private backendEventsService: BackendEventsService
  ) { }

  ngOnInit(): void {
    this.isLoginInProgressSubscription = this.authService.isLoginInProgressChanged.subscribe(
      x => this.isLoginInProgressChanged(x)
    );
    this.isLoginInProgressWithDelay = this.isLoginInProgress;
  }

  ngOnDestroy(): void {
    this.isLoginInProgressSubscription.unsubscribe();
  }


  ngAfterViewInit(): void {
    this.username = this.cookieService.get(COOKIE_KEY);
    this.ngAfterContentChecked();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  isLoginInProgressChanged(value: boolean) {
    if (!value) {
      this.isLoginInProgressWithDelay = value;
      return;
    }

    setTimeout(() => {
      if (value) {
        this.isLoginInProgressWithDelay = this.isLoginInProgress;
      }
    }, 500);
  }

  login() {
    const username = this.username;
    setTimeout(() => this.cookieService.put(COOKIE_KEY, username), 1);
    this.authService.login(username, this.password);
  }

  get loginError(): string {
    return this.authService.loginError;
  }

  get isConnected(): boolean {
    return this.appStateService.appAppAvailabiltyState > AppAvailabilityState.Connecting;
  }

  get isLoginInProgress(): boolean {
    return this.authService.isLoginInProgress;
  }

  get isInitialized(): any {
    return this.backendEventsService.backendEvents.fmcuDate != null;
  }
}
