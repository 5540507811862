import { FormViewSettings } from "src/app/modules/shared/models/form-view-settings";


export class IResource {
  instanceName: string;
  path: string;
  modelObjectId: string;
  resourceIds: string[] = [];
  formViewSettings: FormViewSettings = null;

  constructor() { }

  static arrayFromJSON(json: string): IResource[] {
    const values = JSON.parse(json);
    if (!values || !Array.isArray(values)) {
      return null;
    }

    const result: IResource[] = [];
    for (const item of values) {
      result.push(IResource.createFromAny(item));
    }

    return result;
  }

  static createFromAny(object: any): IResource {
    if (object === null || object === undefined) {
      return null;
    }
    const resource = new IResource();
    resource.instanceName = object.instance_name;
    resource.path = object.path;
    resource.modelObjectId = object.model_object_id;
    resource.resourceIds = object.resource_ids || [];
    if (resource.modelObjectId && resource.resourceIds && resource.resourceIds.length > 0) {
      if (resource.resourceIds[0].indexOf('*') === -1) {
        resource.formViewSettings = new FormViewSettings(true, object.resource_ids);
      }
    }
    return resource;
  }

  toJSON(): any {
    return {
      instanceName: this.instanceName,
      path: this.path,
      modelObjectId: this.modelObjectId,
      resourceIds: this.resourceIds,
    };
  }
}
