export class PythonConvertor {
  static toBoolean(x: boolean): string {
    return x ? 'True' : 'False';
  }

  static toString(x: string): string {
    return `'${x}'`;
  }

  static toTuple(x: Array<any>): string {
    let result = '';

    result += x.reduce((prev, curr, i, arr) => {
      switch (typeof curr) {
        case 'string':
          prev += this.toString(curr);
          break;
        case 'boolean':
          prev += this.toBoolean(curr);
          break;
        case 'number':
          prev += curr;
          break;
        default:
          throw new Error(`Type ${typeof curr} is not supported!`);
      }

      if (i !== arr.length - 1) {
        prev += ', ';
      }

      return prev;
    }, '(');

    return result + ')';
  }
}
