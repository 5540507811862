export class GateContact {
  name: string;
  value: boolean;
  simulatable: boolean;
  actionTag: string;
  setFromRemote: boolean;
  group: string;

  constructor(name: string,
    value: boolean,
    simulatable: boolean,
    actionTag: string,
    setFromRemote: boolean,
    group: string,
    ) {
    this.name = name;
    this.value = value;
    this.simulatable = simulatable;
    this.actionTag = actionTag;
    this.setFromRemote = setFromRemote;
    this.group = group;
  }

  updateFromJson(json: any) {
    this.name = json.name;
    this.value = json.value;
    this.simulatable = json.simulatable;
    this.actionTag = json.action_tag;
    this.setFromRemote = json.set_from_remote;
    this.group = json.group;
  }
}
