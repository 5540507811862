import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { FireAlarm } from '../../../models/bridge/fire-alarm';
import {
  GateModeManagerState,
  GateModeManagerStateEnum
} from '../../../models/bridge/gate-mode-manager-state';
import { BackendHelper } from '../../../models/backend-helper';
import { AudioPlayer } from '../../../models/bridge/audio-player';
import { UsbHub } from '../../../models/bridge/usb-hub';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-gate-controls-general',
  templateUrl: 'gate-controls-general.component.html',
  styleUrls: ['gate-controls-general.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GateControlsGeneralComponent {
  @Input() audioPlayer: AudioPlayer;
  @Input() fireAlarm: FireAlarm;
  @Input() usbHubLocal: UsbHub;
  @Input() usbHubRemote: UsbHub;
  @Input() gateModeManagerState: GateModeManagerState;
  @Input() offButton: boolean;
  @Output() exec = new EventEmitter<string>();
  private backendApi: IBackendApi;

  constructor(
    private backendApiService: BackendApiService,
    public appStateService: AppStateService,
  ) {
    this.backendApi = backendApiService.backendApi;
  }

  get gateModeManagerStateEnum(): any {
    return GateModeManagerStateEnum;
  }

  get titlesWithCssStyles(): any[] {
    return this.gateModeManagerState.titlesWithValues
      .filter(x => x[1])
      .map(x => [x[0],
      x[1] ? 'list-group-item active align-items-center' : 'list-group-item list-group-item-secondary align-items-center']);
  }

  onMakePhotoShotClick() {
    const param = 'Ticket.create_photo_file_name_without_extension_static()';
    this.exec.next(
      `${BackendHelper.getPathDeviceMethod(
        'camera_service',
        'get_photo'
      )}(${param})`
    );
  }

  toNormal() {
    this.backendApi.setGateModeNormal().subscribe(() => { }, e => console.error(e));
  }

  toLocked() {
    this.backendApi.setGateModeLocked().subscribe(() => { }, e => console.error(e));
  }

  toLockedEntry() {
    this.backendApi.setGateModeLockedEntry().subscribe(() => { }, e => console.error(e));
  }

  toLockedExit() {
    this.backendApi.setGateModeLockedExit().subscribe(() => { }, e => console.error(e));
  }

  toServiceEntry() {
    this.backendApi.setGateModeServiceEntry().subscribe(() => { }, e => console.error(e));
  }

  toServiceExit() {
    this.backendApi.setGateModeServiceExit().subscribe(() => { }, e => console.error(e));
  }

  toSelfTest() {
    this.backendApi.setGateModeSelfTest().subscribe(() => { }, e => console.error(e));
  }

  resetWinner() {
    this.backendApi.randomWinnerReset().subscribe(() => { }, e => console.error(e));
  }

  onOffChanged(value) {
    this.backendApi.setGateModeOff(!value).subscribe(() => { }, e => console.error(e));
  }

  get isRandomWinnerExist(): boolean {
    return this.appStateService.appState.randomWinnerExist;
  }
}
