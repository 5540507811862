export class ServerStatus {
  type: string;
  instanceName: string;
  value: boolean;
  state: string;
  isEnabled: boolean;
  isSimulator: boolean;
  lastError: string;

  updateFromJson(json: any) {
    this.type = json.type;
    this.state = json.state;
    this.isEnabled = json.is_enabled;
    this.isSimulator = json.is_simulator;
    this.lastError = json.last_error;
  }
}
