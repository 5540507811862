import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UsbHub } from '../../../models/bridge/usb-hub';

@Component({
      selector: 'app-gate-controls-usb-hub',
    templateUrl: 'gate-controls-usb-hub.component.html',
    styleUrls: ['gate-controls-usb-hub.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GateControlsUsbHubComponent {
  @Input() usbHub: UsbHub;
  @Input() isUiControlsEnabled: boolean;

  isItemEnabled(x: any) {
      return this.isUiControlsEnabled && x.enabled;
  }
}
