import { Component, Input } from '@angular/core';
import { RandomWinnerTracker } from 'src/app/models/random-winner/random-winner-tracker';

@Component({
  selector: 'app-random-winner-tracker',
  templateUrl: 'random-winner-tracker.component.html',
  styleUrls: ['random-winner-tracker.component.scss']
})
export class RandomWinnerTrackerComponent {
  @Input() trackerEntry: RandomWinnerTracker;
  @Input() trackerExit: RandomWinnerTracker;
}
