import { Bridge } from './bridge';
import { BackendHelper } from '../backend-helper';
import { PythonConvertor } from '../python-convertor';

export class FireAlarm extends Bridge<boolean> {
  constructor() {
    super(false);
  }

  get exec(): string {
    const method = BackendHelper.getPathDeviceMethod('gate', 'is_fire_alarm');
    return `${method}=${PythonConvertor.toBoolean(this.value)}`;
  }
}
