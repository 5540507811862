import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BackendApiService } from 'src/app/services/backend-api/backend-api.service';
import { IBackendApi } from 'src/app/services/backend-api/backend-api.interface';
import { FirstSetupStepsStateService } from 'src/app/services/first-setup-steps-state.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-installation-executable-device-type',
  templateUrl: './installation-executable-device-type.component.html',
  styleUrls: ['./installation-executable-device-type.component.css']
})
export class InstallationExecutableDeviceTypeComponent implements OnInit, OnDestroy {
  private backendApi: IBackendApi;

  installerGateConfigurations: any;
  installerGateType: string = null;
  installerConfigurationList: any[];
  installerGateTypeTags = {};

  currentConfigurationType: string = null;
  currentGateType: string = null;
  currentSelectedTemplateConfigurations: string[] = null;

  @Input()
  checkCurrentConfigurationType = false;

  installerSettingsFileName: string = null;
  installerSettingsDescription: string = null;
  installerGateConfigurationId: string = null;

  templateConfigurations = [];
  templateConfigurationValues = {}
  templateConfigurationsSubscription: Subscription;
  templateConfigurationChanged = false;

  constructor(
    private backendApiService: BackendApiService,
    private firstSetupStepsStateService: FirstSetupStepsStateService,
  ) {
    this.backendApi = backendApiService.backendApi;
  }

  ngOnInit(): void {
    this.loadInstallerConfiguration();
  }

  ngOnDestroy(): void {
    this.unsubscribeTemplateConfigurationsSubscription();
  }

  private loadInstallerConfiguration() {
    this.backendApi.getInstallerGateConfigurations().subscribe(
      x => {
        if (!x || !x.installerGateConfigurations) {
          return;
        }

        this.installerGateConfigurations = x.installerGateConfigurations;
        this.currentGateType = x.currentGateType;
        this.currentSelectedTemplateConfigurations = x.currentSelectedTemplateConfigurations;
        this.currentConfigurationType = x.currentConfigurationType;
        this.firstSetupStepsStateService.currentConfigurationType = this.currentConfigurationType; 

        if (this.installerGateConfigurations && this.installerGateConfigurations.length > 0) {
          let currentGateTypeExistInGateConfigurations = false;
          if (this.checkCurrentConfigurationType) {
            for (const installerGateConfiguration of this.installerGateConfigurations) {
              if (installerGateConfiguration.gate_device_class === this.currentGateType) {
                currentGateTypeExistInGateConfigurations = true;
                break;
              }
            }
          }

          this.installerGateType = currentGateTypeExistInGateConfigurations
          ? this.currentGateType : this.installerGateConfigurations[0].gate_device_class;
          this.initInstallerGateTypeTags();
          this.onChangeInstallerGateType();
          this.loadTemplateConfigurationValues();
        }
      });
  }

  unsubscribeTemplateConfigurationsSubscription() {
    if (this.templateConfigurationsSubscription) {
      this.templateConfigurationsSubscription.unsubscribe();
      this.templateConfigurationsSubscription = null;
    }
  }

  loadTemplateConfigurationValues(): void {
    this.unsubscribeTemplateConfigurationsSubscription();
    this.templateConfigurationsSubscription = this.backendApi.getTemplateConfigurations().subscribe(
      x => {
        if (x.template_configurations && x.template_configurations.length > 0) {
          this.templateConfigurations = x.template_configurations;
        }

        if (!this.templateConfigurations || this.templateConfigurations.length == 0) {
          return;
        }
    
        for (const templateConfiguration of this.templateConfigurations) {
          if (templateConfiguration.object_id) {
            let isDefaultSelected = this.currentSelectedTemplateConfigurations && this.currentSelectedTemplateConfigurations.indexOf(templateConfiguration.object_id) != -1;
            this.templateConfigurationValues[templateConfiguration.object_id] = isDefaultSelected;
          }
        }
      });    
  }

  initInstallerGateTypeTags() {
    if (!this.installerGateConfigurations) {
      return;
    }

    for (const installerGateConfiguration of this.installerGateConfigurations) {
      const gateType = installerGateConfiguration.gate_device_class;
      if (!this.initInstallerGateTypeTags || this.installerGateTypeTags && !(gateType in this.installerGateTypeTags)) {
        this.installerGateTypeTags[gateType] = [];
      }

      for (const configurationList of installerGateConfiguration.configuration_list) {
        if (!configurationList.tags || configurationList.tags.length === 0) {
          continue;
        }

        for (const tag of configurationList.tags) {
          if (this.installerGateTypeTags[gateType].indexOf(tag) !== -1) {
            continue;
          }
          this.installerGateTypeTags[gateType].push(tag);
        }
      }
    }

    if (this.installerGateTypeTags) {
      Object.entries(this.installerGateTypeTags).forEach(
        ([gateType, tags]) => {
          this.installerGateTypeTags[gateType].sort((a, b) => a > b ? 1 : -1);
        }
      );
    }

  }

  onChangeInstallerGateType() {
    this.updateInstallerConfigurationList();
    this.resetInstallerSettings();
    if (this.installerConfigurationList && this.installerConfigurationList.length > 0) {
      for (const installerConfigurationList of this.installerConfigurationList) {
        if (this.checkCurrentConfigurationType &&
          this.currentConfigurationType
          && this.currentConfigurationType === installerConfigurationList.gate_configuration_id) {
          this.setConfigurationTypeByConfigurationList(installerConfigurationList);
          break;
        }

        if (installerConfigurationList.default) {
          this.setConfigurationTypeByConfigurationList(installerConfigurationList);
        }
      }

      this.fillDefaultInstallerSettings();
    }
  }

  resetInstallerSettings() {
    this.installerSettingsFileName = '';
    this.installerSettingsDescription = '';
    this.installerGateConfigurationId = '';
    this.firstSetupStepsStateService.installerGateConfigurationId = '';
  }

  setConfigurationTypeByConfigurationList(configurationList) {
    if (!configurationList) {
      return;
    }

    // if (configurationList.tags && configurationList.tags.length > 0) {
    //   this.selectedTags = [configurationList.tags[0]];
    // }
    this.installerSettingsFileName = configurationList.settings_file_name;
    this.installerSettingsDescription = configurationList.description;
    this.installerGateConfigurationId = configurationList.gate_configuration_id;
    this.firstSetupStepsStateService.installerGateConfigurationId = this.installerGateConfigurationId;
  }

  updateInstallerConfigurationList() {
    this.resetInstallerSettings();

    if (!this.installerGateType) {
      return;
    }


    let currentGateConfiguration = null;
    for (const installerGateConfiguration of this.installerGateConfigurations) {
      const gateType = installerGateConfiguration.gate_device_class;
      if (gateType !== this.installerGateType) {
        continue;
      }
      currentGateConfiguration = installerGateConfiguration;
      break;
    }

    if (!currentGateConfiguration) {
      return;
    }

    const configurationLists = currentGateConfiguration.configuration_list;    
    this.installerConfigurationList = configurationLists;
    

    this.fillDefaultInstallerSettings();
  }

  fillDefaultInstallerSettings() {
    if (!this.installerConfigurationList || !this.installerConfigurationList.length) {
      return;
    }
    if (!this.installerSettingsFileName) {
      this.installerSettingsFileName = this.installerConfigurationList[0].settings_file_name;
    }
    if (!this.installerSettingsDescription) {
      this.installerSettingsDescription = this.installerConfigurationList[0].description;
    }
    if (!this.installerGateConfigurationId) {
      this.installerGateConfigurationId = this.installerConfigurationList[0].gate_configuration_id;
      this.firstSetupStepsStateService.installerGateConfigurationId = this.installerGateConfigurationId;
    }
  }

  onSelectConfiguration(installerGateConfiguration) {
    this.installerSettingsFileName = installerGateConfiguration.settings_file_name;
    this.installerSettingsDescription = installerGateConfiguration.description;
    this.installerGateConfigurationId = installerGateConfiguration.gate_configuration_id;
    this.firstSetupStepsStateService.installerGateConfigurationId = this.installerGateConfigurationId;
  }

  changeSelectTemplateConfiguration(templateConfiguration, event): void {
    if (!templateConfiguration || !templateConfiguration.object_id) {
      return;
    }

    const templateConfigurationObjectId = templateConfiguration.object_id;
    const value = event.currentTarget.checked;

    let conflictTemplateConfigurations = null;
    if (templateConfiguration.conflict_template_configurations) {
      conflictTemplateConfigurations = templateConfiguration.conflict_template_configurations.replace(' ', '').split(",");
    }

    if (value && conflictTemplateConfigurations && conflictTemplateConfigurations.length > 0) {
      for (const conflictTemplateConfiguration of conflictTemplateConfigurations) {
        this.templateConfigurationValues[conflictTemplateConfiguration] = false;
      }
    }

    let templateConfigurationChanged = false
    for (const templateConfigurationKey in this.templateConfigurationValues) {
      const value = this.templateConfigurationValues[templateConfigurationKey];
      if (value && this.currentSelectedTemplateConfigurations &&  this.currentSelectedTemplateConfigurations.indexOf(templateConfigurationKey) == -1) {
          templateConfigurationChanged = true;
      }
    }

    this.templateConfigurationChanged = templateConfigurationChanged;
  }

  get selectedTemlateConfigurations() {
    let selectedTemlateConfigurations = [];
    if (!this.templateConfigurationValues) {
      return selectedTemlateConfigurations;
    }

    for (const templateConfigurationKey in this.templateConfigurationValues) {
      const value = this.templateConfigurationValues[templateConfigurationKey];
      if (value) {
        selectedTemlateConfigurations.push(templateConfigurationKey)
      }
    }

    return selectedTemlateConfigurations;
  }

}
