import { Component, OnInit } from '@angular/core';
import { RolesService } from 'src/app/services/backend-api/roles.service';
import { FormViewSettings } from '../../shared/models/form-view-settings';

@Component({
  selector: 'app-configuration-device',
  templateUrl: './configuration-device.component.html',
  styleUrls: ['./configuration-device.component.css']
})
export class ConfigurationDeviceComponent implements OnInit {

  constructor(
    private rolesService: RolesService,
  ) { }

  ngOnInit(): void {
  }

  get apiUrlModel(): string {
    return this.rolesService.currentSetupStepPath;
  }

  get apiUrlModelObjectId(): string {
    return this.rolesService.currentSetupStepModelObjectId;
  }

  get formViewSettings(): FormViewSettings {
    return this.rolesService.currentSetupStepFormViewSettings;
  }
}
